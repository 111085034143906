export const dataAuthCarousel = [
  {
    src: "/images/authCarousel/IMG_0465.JPG",
    altText: "Знижка на вартість",
    caption:
      "Ціни на авто на аукціонах CarCool часто нижчі, ніж на традиційних ринках, що дає можливість отримати вигідну угоду.",
  },
  {
    src: "/images/authCarousel/IMG_0459.JPG",
    altText: "Різноманітність моделей і брендів",
    caption:
      "На аукціонах CarCool представлено широкий вибір автомобілів різних марок, моделей і років випуску, що дозволяє знайти саме те, що підходить за потребами.",
  },
  {
    src: "/images/authCarousel/IMG_0462.JPG",
    altText: "Доступ до ексклюзивних моделей",
    caption:
      "На CarCool можна знайти авто, які рідко зустрічаються на традиційних ринках, такі як ретро автомобілі або спортивні моделі.",
  },
  {
    src: "/images/authCarousel/IMG_0460.JPG",
    altText: "Прозорість процесу",
    caption:
      "Наш аукціон CarCool надає детальну інформацію про стан автомобілів, їх історію і технічний огляд, що робить процес покупки більш прозорим.   ",
  },
  {
    src: "/images/authCarousel/IMG_0463.JPG",
    altText: "Швидкість та ефективність",
    caption:
      "Процес купівлі авто на аукціонах CarCool може бути значно швидшим порівняно з традиційними методами, оскільки ви можете здійснювати покупку в будь-якому місці.",
  },
];
