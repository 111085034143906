import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Identification from "../../UI/Identification";
import { useApi } from "../../Contexts/ApiContext";

const EmailConfirm = () => {
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");
  const token = decodeURIComponent(urlParams.get("token"));
  const api = useApi(
    `AccountManager/ConfirmEmail?userId=${encodeURIComponent(
      userId
    )}&token=${encodeURIComponent(token)}`
  );

  useEffect(() => {
    if (userId && token) {
      fetch(api, {
        method: "GET",
      }).then((response) => {
        if (response.ok) {
          setStatusMessage("Ваш акаунт успішно підтверджено");
        } else {
          setStatusMessage(
            "Відбулась помилка. Ваш акаунт не було підтверджено"
          );
        }
      });
    }
  }, [api, userId, token]);

  const openLogin = () => {
    navigate("/login");
  };

  return (
    <Identification
      header="Підтвердження аккаунту"
      contextText={
        <>
          <p>{statusMessage}</p>
          {statusMessage.includes("успішно") && (
            <>
              <p style={{ display: "inline-block" }}>
                Повернутися до авторизації
              </p>
            </>
          )}
        </>
      }
      linkText="Увійти"
      clickEvent={openLogin}
      hideGoogleButton={true}
    />
  );
};

export default EmailConfirm;
