import React, { useState, useEffect, useRef } from "react";
import styles from "./Gallery.module.css";

const ImageModal = ({ images, initialImage, onClose, isView }) => {
  const [selectedImage, setSelectedImage] = useState(initialImage);
  const [currentIndex, setCurrentIndex] = useState(
    images.indexOf(initialImage)
  );
  const [viewMode, setViewMode] = useState(isView); // 'grid' or 'image'
  const modalRef = useRef(null);

  useEffect(() => {
    setSelectedImage(initialImage);
    setCurrentIndex(images.indexOf(initialImage));
    document.body.style.overflow = "hidden";
    modalRef.current.focus();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [initialImage, images]);

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setViewMode("image");
  };

  const showPreviousImage = () => {
    const previousIndex =
      currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setSelectedImage(images[previousIndex]);
    setCurrentIndex(previousIndex);
  };

  const showNextImage = () => {
    const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setSelectedImage(images[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const renderGrid = () => (
    <div className={styles.grid}>
      {images.map((image, index) => (
        <div
          className={styles.gridImage}
          key={index}
          onClick={() => handleImageClick(image, index)}
        >
          <img src={image} alt={`Gallery ${index + 1}`} />
        </div>
      ))}
    </div>
  );

  const renderSelectedImage = () => (
    <div className={styles.selectedImage}>
      {images.length > 1 && (
        <button
          className={`${styles.navButton} ${styles.prev}`}
          onClick={showPreviousImage}
        >
          &larr; Prev
        </button>
      )}
      <img className="modal-content" src={selectedImage} alt="Large" />
      {images.length > 1 && (
        <button
          className={`${styles.navButton} ${styles.next}`}
          onClick={showNextImage}
        >
          Next &rarr;
        </button>
      )}
    </div>
  );

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  return (
    <div
      className={styles.modal}
      ref={modalRef}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div className={styles.modalBody} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalMenu}>
          <button onClick={() => setViewMode("grid")}>Grid View</button>
          <button onClick={() => setViewMode("image")}>Image View</button>
          {viewMode === "image" && (
            <span className={styles.count}>
              {currentIndex + 1} / {images.length}
            </span>
          )}
          <span className={styles.close} onClick={onClose}>
            &times;
          </span>
        </div>
        {viewMode === "grid" ? renderGrid() : renderSelectedImage()}
      </div>
    </div>
  );
};

export default ImageModal;
