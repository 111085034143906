import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import CarTable from "../UI/CarTable";
import CarTableEdit from "./CarTableEdit";
import CarDescriptionEdit from "./CarDescriptionEdit";
import TextBlock from "../UI/TextBlock";
import styles from "./CardDeteilsModeration.module.css";
import ButtonEditModeration from "../UI/ButtonEditModeration";
import { useToast } from "../Contexts/ToastContext";
import useFetchData from "../Hooks/useFetchData";
import RequestType from "../Models/RequestType";
import { useAuth } from "../Contexts/AuthContext";
import Loading from "../UI/Loading";

//Select custom style
const customStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "var(--dark-gray)" : "var(--dark-gray)",
    "& svg": {
      width: "30px",
      height: "30px",
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--dark-gray)",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "22px",
    background: state.isSelected ? "var(--primary-blue)" : "var(--bs-white)",
    color: state.isSelected ? "white" : "var(--text-color)",
    "&:hover": {
      background: "#0097b26b",
      color: "white",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    border: "4px solid var(--bs-red)",
    borderRadius: "10px",
    padding: "10px",
    fontSize: "22px",
    background: "var(--light-gray)",
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: "var(--bs-red)",
    },
  }),
};

const CardDeteilsModeration = () => {
  const [car, setCar] = useState({});
  const [incorrect, setIncorrect] = useState([]);
  const [incorrectSelect, setIncorrectSelect] = useState(null);
  const [incorrectDescription, setIncorrectDescription] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editMode, setEditMode] = useState({
    carDescription: false,
    completeSetOfCars: false,
    modifiedList: false,
    disadvantagesList: false,
    serviceHistory: false,
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { user } = useAuth();
  const { fetchData: fetchDetailsCarData } = useFetchData(
    `Moderator/GetDetailCarInModeration/${id}`
  );
  const { fetchData: fetchModertionLotData } = useFetchData(
    "Moderator/ModerationLot",
    RequestType.POST
  );

  const transformObject = (data) => {
    if (!data || typeof data !== "object") {
      return [];
    }
    return Object.entries(data).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  };

  const handleSaveLotModeration = (audit) => {
    const checkFailedValue = incorrectSelect
      ? parseInt(incorrectSelect.value)
      : null;
    const requestData = {
      LotID: car.lotID,
      ModeratorID: user?.id,
      OwnerID: car.ownerID,
      OwnerName: car.ownerName,
      RegistrationCarNumber: car.registrationCarNumber,
      Brand: car.brand.value,
      Model: car.model.value,
      Mileage: car.mileage,
      Body: parseInt(car.body.key),
      Drive: parseInt(car.drive.key),
      Engine: car.engine,
      Gearbox: parseInt(car.gearbox.key),
      FuelType: parseInt(car.fuelType.key),
      Color: car.color,
      Region: parseInt(car.region.key),
      CarDescription: car.carDescription,
      ServiceHistoryDescription: car.serviceHistoryDescription,
      CompleteSetOfCars: car.completeSetOfCars.map(
        (complectation) => complectation
      ),
      ModifiedList: car.modifiedList.map((modification) => modification),
      DisadvantagesList: car.disadvantagesList.map(
        (disadvantage) => disadvantage
      ),
      Audit: audit,
      CheckFailed: checkFailedValue,
      DescriptionCheckFailed: incorrectDescription,
    };

    fetchModertionLotData(requestData)
      .then((data) => {
        showToast("Успіх", data, "success");
        navigate(`/InModeration`);
      })
      .catch((err) => {
        showToast(
          "Помилка",
          `Помилка відправлення даних на сервер: ${err}`,
          "danger"
        );
      });
  };

  useEffect(() => {
    fetchDetailsCarData()
      .then((data) => {
        setCar(data.lot);
        setIncorrect(transformObject(data.incorrectLot));
      })
      .catch((err) => {
        console.log("Error fetching data:", err);
      });
  }, [fetchDetailsCarData]);

  const handleSelectChange = (selectedOption) => {
    setIncorrectSelect(selectedOption);
    checkButtonState(selectedOption);
  };

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    setIncorrectDescription(value);
  };

  const checkButtonState = (selectedOption) => {
    if (selectedOption) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleAuditSuccess = (event) => {
    event.preventDefault();
    handleSaveLotModeration(true);
  };

  const handleAuditFail = (event) => {
    event.preventDefault();
    handleSaveLotModeration(false);
  };

  const handleSaveChangesDescription = (editedDescription) => {
    setCar({ ...car, ...editedDescription });
  };

  const handleSaveChanges = (editedCar) => {
    setCar(editedCar);
    setIsEditing(false);
  };

  if (Object.keys(car).length === 0 && car.constructor === Object) {
    return <Loading />;
  }

  return (
    <div className="col-xxl-12">
      {isEditing ? (
        <CarTableEdit
          car={car}
          onSaveChanges={handleSaveChanges}
          onCancelEdit={handleCancelEdit}
        />
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="col-xxl-8 mb-4">
            <CarTable car={car} />
          </div>
          <ButtonEditModeration handleClick={handleEditClick} />
        </div>
      )}
      <CarDescriptionEdit
        car={car}
        onSaveChangesDescription={handleSaveChangesDescription}
        editMode={editMode}
        setEditMode={setEditMode}
      />
      <div className="col-xxl-8">
        <div className="d-flex justify-content-between mb-4">
          <div>
            <button
              style={{
                background:
                  isButtonDisabled ||
                  isEditing ||
                  Object.values(editMode).some((mode) => mode)
                    ? "var(--light-custom-gray)"
                    : "var(--bs-red)",
              }}
              onClick={handleAuditFail}
              disabled={
                isButtonDisabled ||
                isEditing ||
                Object.values(editMode).some((mode) => mode)
              }
              className={styles.btnWarning}
            >
              <img
                alt="lock"
                src="/images/padlock.png"
                style={{ width: "70px" }}
              />
              <strong>Перевірку не пройдено, є помилки</strong>
            </button>
          </div>
          <div>
            <button
              style={{
                background:
                  isEditing || Object.values(editMode).some((mode) => mode)
                    ? "var(--light-custom-gray)"
                    : "var(--saturated-green)",
              }}
              onClick={handleAuditSuccess}
              disabled={
                isEditing || Object.values(editMode).some((mode) => mode)
              }
              className={styles.btnSuccess}
            >
              <strong>Перевірка пройдена успішно</strong>
            </button>
          </div>
        </div>
        <h2 className="mb-4">
          <strong>Обрати, який пункт не пройшов перевірку</strong>
        </h2>
        <Select
          options={incorrect}
          value={incorrectSelect}
          onChange={handleSelectChange}
          placeholder="Вибрати пункт..."
          styles={{
            ...customStyles,
          }}
        />
        <TextBlock color="#fff" className="cardText">
          <h2>
            <strong>Деталізувати, в чому саме помилка</strong>
          </h2>
          <textarea
            placeholder="Введіть текст..."
            value={incorrectDescription}
            onChange={handleDescriptionChange}
            className={styles.textareaDescription}
          />
        </TextBlock>
      </div>
    </div>
  );
};

export default CardDeteilsModeration;
