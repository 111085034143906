import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./ModalWindowAlert.module.css";

const ModalWindowAlert = ({
  modalOpen,
  setModalOpen,
  text,
  handleDeleteConfirm,
}) => {
  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => setModalOpen(false)}
      contentClassName={styles.alert}
      centered
    >
      <ModalHeader toggle={() => setModalOpen(false)}>
        Підтвердження видалення
      </ModalHeader>
      <ModalBody>{text}</ModalBody>
      <ModalFooter className={styles.alertFooter}>
        <Button color="danger" onClick={handleDeleteConfirm}>
          Так
        </Button>{" "}
        <Button color="secondary" onClick={() => setModalOpen(false)}>
          Ні
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalWindowAlert;
