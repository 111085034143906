import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./UniversalModalWindow.module.css";

const UniversalModalWindow = ({
  modalOpen,
  setModalOpen,
  header,
  children,
  footerChildren,
}) => {
  return (
    <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} centered>
      <ModalHeader toggle={() => setModalOpen(false)}>{header}</ModalHeader>
      <ModalBody className={styles.body}>{children}</ModalBody>
      <ModalFooter>{footerChildren}</ModalFooter>
    </Modal>
  );
};

export default UniversalModalWindow;
