import React from "react";
import { ReactComponent as IconLoadLine } from "../../assets/icons/loader-line.svg";
import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.position}>
      <div className={styles.containerSpin}>
        Завантаження
        <IconLoadLine className={styles.spinner} />
      </div>
    </div>
  );
};

export default Loading;
