import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import styles from "../Pages/ExhibitingACar.module.css";

const Hint = ({
  id,
  popoverOpen,
  togglePopover,
  hint,
  placement = "bottom",
}) => {
  return (
    <>
      <img
        src="/images/Question_mark.png"
        className={styles.imgQuestion}
        id={id}
        alt="Question"
        onMouseEnter={togglePopover}
        onMouseLeave={togglePopover}
      />
      <Popover
        placement={placement}
        isOpen={popoverOpen}
        target={id}
        toggle={togglePopover}
      >
        <PopoverBody>{hint}</PopoverBody>
      </Popover>
    </>
  );
};

export default Hint;
