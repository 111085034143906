import React from "react";
import Identification from "../../UI/Identification";
import { useNavigate } from "react-router-dom";

const ForgotPasswordConfirmation = () => {
  const navigate = useNavigate();
  const openLogin = () => {
    navigate("/login");
  };
  return (
    <Identification
      header="Підтвердження забутого пароля"
      contextText={
        <>
          <p>
            Ми надіслали вам електронний лист з інструкціями щодо скидання
            пароля. Будь ласка, перевірте свою електронну пошту.
          </p>
          <p style={{ display: "inline-block" }}>Повернутися до авторизації</p>
        </>
      }
      linkText="Увійти"
      clickEvent={openLogin}
      hideGoogleButton={true}
    >
      {" "}
    </Identification>
  );
};

export default ForgotPasswordConfirmation;
