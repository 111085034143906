import React, { useEffect, useState } from "react";
import { Form, FormGroup } from "reactstrap";
import TextInput from "../../UI/TextInput";
import AuthButton from "../../UI/AuthButton";
import Identification from "../../UI/Identification";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../Contexts/ApiContext";
import { useAuth } from "../../Contexts/AuthContext";
import styles from "../../UI/Identification.module.css";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [apiUrl, setApiUrl] = useState("");
  const [error, setError] = useState(null);
  const apiUrlFromContext = useApi();
  const { forgotPassword } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setApiUrl(apiUrlFromContext);
  }, [apiUrlFromContext]);

  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setError("Введіть дійсну електронну адресу.");
      return;
    }

    try {
      const result = await forgotPassword(formData, apiUrl);
      if (result.success === false) {
        setError(result.message);
      } else {
        navigate("/forgotpasswordconfirmation");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const openLogin = () => {
    navigate("/login");
  };

  return (
    <Identification
      header="Відновлення паролю"
      contextText="Потрібно увійти?"
      linkText="Увійти"
      clickEvent={openLogin}
      hideGoogleButton={true}
    >
      {error && (
        <div
          className={styles.errorMessage}
          dangerouslySetInnerHTML={{ __html: error }}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <TextInput
            type="email"
            id="email"
            placeholder="Email"
            value={formData.email}
            onChange={(value) => handleChange("email", value)}
          />
        </FormGroup>
        <AuthButton type="submit">Відновити</AuthButton>
      </Form>
    </Identification>
  );
};

export default ForgotPassword;
