export const questionsBuyers = [
  {
    title: "Які комісії для покупця на Carcool?",
    text: "На додаток до остаточної ціни покупки, сплаченої продавцю, покупці сплачують 4,5% комісії покупця Carcool. Комісія покупця становить мінімум 90y.o.",
  },
  {
    title: "Як мені зареєструватися для участі в торгах?",
    text: "Щоб зареєструватися, спочатку зареєструйтеся, натиснувши піктограму «Увійти» у верхньому правому куті екрана, а потім натисніть «Зареєструватися тут» у вікні, яке з’явиться згодом. Після створення імені користувача та пароля вам буде запропоновано підтвердити адресу електронної пошти. Після того, як ви це зробите, ви можете повернутися до Carcool, і вам буде запропоновано зареєструватися для участі в ставках.<br/><br/> Якщо ви ще не хочете реєструватися, не біда – ви можете зробити це пізніше. Коли ви знайдете автомобіль, на який хочете зробити ставку, натисніть «Зробити ставку» на сторінці списку автомобіля, і вам буде запропоновано зареєструватися, перш ніж ви зможете зробити ставку. Вам доведеться ввести свою банківську карту та паспортні дані, оскільки ми заморожуємо частину коштів картки кожного учасника аукціону до завершення аукціону.",
  },
  {
    title: "Як зробити ставку?",
    text: "Щоб зробити ставку, вам спочатку потрібно зареєструватися, про що ми пояснили вище. Після того, як ви зареєструвалися та знайшли автомобіль, який вас цікавить, робити ставки легко – просто натисніть значок «Зробити ставку» на сторінці транспортного засобу. Потім вам буде запропоновано ввести суму ставки.<br/> Звичайно, ваша ставка має бути вищою за попередню ставку – і залежно від поточного рівня ставок може бути мінімальне збільшення попередньої ставки. Після того, як ви подасте свою ставку, ми затримаємо на вашій картці 4,5% суми вашої ставки до закінчення аукціону, якщо ви станете переможцем торгів.",
  },
  {
    title: "Як працює збільшення ставок?",
    text: "Приріст ставки збільшується таким чином: <br/>&emsp;<strong>Мінімальна</strong> ставка <strong>50</strong> у.о. для початку аукціону <br/>&emsp;<strong>50 у.о</strong> збільшуються до 4999 у.о.<br/>&emsp;<strong>100 у.о.</strong> збільшуються з 5000 у.о до 14 999 у.о <br/>&emsp;<strong>250 у.о.</strong> збільшується з 15 000 у.о. до 49 999 у.о. <br/>&emsp;<strong>500 у.о.</strong> збільшується з 50 000 у.о. до 199 999 у.о. <br/>&emsp;<strong>1000 у.о.</strong> збільшуються на рівні 200 000 у.о. або вище",
  },
  {
    title: "Яку валюту використовує Cars & Bids?",
    text: "Усі ставки на автомобілі та ставки вказані в USD",
  },
  {
    title: "Чи можу я робити ставки, якщо живу за межами України?",
    text: "Так, однак вам потрібно буде повністю заплатити за автомобіль одразу після закриття аукціону, як і всім іншим покупцям. Крім того, будь-яка логістика, імпортні збори, транспортування, юридичні збори та/або інші предмети, необхідні для експорту/імпорту автомобіля, повністю несе відповідальність покупця. Будь ласка, переконайтеся, що ви ознайомлені з усіма цими правилами, і у вас є все на місці, перш ніж робити ставки. Закриття аукціону – не час починати досліджувати транспортну логістику та правила імпорту.",
  },
  {
    title: "Як зв'язатися з продавцем приватно?",
    text: "Ви можете звязатись з продавцем в публічному чаті аукціону, також ми надаємо контакт продавця людині яка виграла аукціон.",
  },
  {
    title: "Як дізнатися, чи пройшов техогляд автомобіль?",
    text: "Якщо автомобіль перевірено, ви побачите тег «Перевірено» біля заголовка списку. На сторінці списку буде посилання для перегляду звіту про перевірку під головним зображенням лістингу поруч із логотипом Carvertical.",
  },
  {
    title: "Що охоплює перевірка?",
    text: "Перевірки проводяться ОПІНСПЕКТОР і включають деталі екстер’єру, салону та стану експлуатації автомобіля. Переглянути зразок акта перевірки можна <a href='https://opinspector.com.ua/elementor-8494/' class='linkBlack'>тут</a>. <br/>Додаткові відомості доступні на https://opinspector.com.ua/pidbir-avto-kyiv/",
  },
  {
    title: "Чи проходять перевірені автомобілі тест-драйв?",
    text: "Інспектори тестуватимуть транспортний засіб, якщо він дозволений на вулицях, безпечний у їзді та продавець дозволяє їм керувати ним. У деяких випадках інспектор може не мати змоги провести тест-драйв транспортного засобу через обмеження розміру або проблеми з відповідальністю. У таких випадках продавець або представник продавця може пройти тест-драйв автомобіля.",
  },
  {
    title: "Чому ви заморожуєте мою картку, коли я ставлю ставку?",
    text: "Ми заморожуємо вашу кредитну картку, коли ви робите ставку, щоб врахувати ймовірність того, що вам, можливо, доведеться сплатити комісію покупця, якщо ви станете переможцем торгів. Утримання становить 4,5% від вашої початкової суми ставки, мінімально 90у.о. Якщо ви не переможете, утримання з вашої кредитної картки зніметься після завершення аукціону.",
  },
  {
    title: "Я не виграв аукціон, чому я все ще маю утримання від Cars & Bids?",
    text: "Ми скасовуємо утримання для учасників, які не виграли аукціон, одразу після завершення аукціону. Залежно від вашого банку може знадобитися кілька днів або до 7 робочих днів, у рідкісних випадках, щоб утримання знялось. будь ласка, зв’яжіться з емітентом вашої картки з будь-якими запитаннями щодо термінів і процесу.",
  },
  {
    title: "Яка резервна ціна автомобіля, який я хочу купити?",
    text: "Якщо на оголошенні немає резерву, ви побачите тег «Без резерву» разом із текстом «Без резерву» біля вікна ставок. Якщо ви не бачите цього тексту, цей аукціон має резервну ціну. Резервні ціни не публікуються, і ми просимо покупців утримуватися від запитань у продавців про резервні ціни під час аукціону. Єдиний раз, коли ви дізнаєтесь, чи резерв виконано, це якщо автомобіль буде продано на аукціоні.",
  },
  {
    title: "Що робити, якщо резерв не виконано?",
    text: "Якщо ви робите найвищу ставку на автомобіль, резерв якого не досягнутий, ми працюватимемо з вами та продавцем, щоб знайти ціну продажу, яка підходить для обох сторін. Якщо ми зможемо досягти згоди, ми зв’яжемо учасника найвищої ціни з продавцем – і наша комісія знизиться до 2,5%. Лише той, хто запропонує найвищу ставку, має право укласти угоду після аукціону. Робіть найвищу ставку під час аукціону, оскільки це найкращий спосіб гарантувати, що ви отримаєте потрібний вам автомобіль.",
  },
  {
    title: "Як завершити транзакцію після завершення аукціону?",
    text:
      "Після завершення аукціону покупець і продавець отримують контактну" +
      "інформацію один одного для завершення операції. Докладніше про" +
      " <a href='/' class='linkBlack'>завершення транзакції.</a>",
  },
  {
    title: "Як забрати автомобіль?",
    text:
      "Витрати на транспортування завжди покладаються на покупця, тому" +
      " ми рекомендуємо зібрати особисту оцінку вартості подорожі та/або" +
      " доставки перед тим, як робити ставки. Є багато варіантів того, як" +
      " забрати свій новий автомобіль, наприклад, забронювати рейс в один" +
      " кінець і поїхати додому або поїхати з причепом, щоб забрати" +
      " автомобіль. Однак ми завжди пропонуємо доставку як простий і часто" +
      " більш доступний варіант.",
  },
  {
    title: "Чи є правила, яких слід дотримуватися в коментарях?",
    text:
      "Так! Окрім правил здорового глузду, таких як лексика та особисті напади," +
      " розмова має бути зосереджена на транспортному засобі, який продається," +
      " щоб бути чесним щодо продавця та учасників торгів. Ми хочемо, щоб" +
      " спільнота Carcool залишалася відкритим і позитивним місцем для" +
      " проведення часу з іншими учасниками. Неповний список речей, які" +
      " викликають несхвалення (і будуть відповідно модеровані), включає:" +
      "<br><div><ul>" +
      "<li>Нецензурна лексика</li>" +
      "<li>Особисті нападки</li>" +
      "<li>Спроба поділитися вашою особистою контактною інформацією</li>" +
      "<li>Розсилка спаму</li>" +
      "<li>Розпитуючи продавця про їх резерв</li>" +
      "<li>Назвіть ціну, за яку, на вашу думку, буде продано автомобіль</li>" +
      "<li>Невідповідні зображення або описи у вашому профілі</li>" +
      "<li>Підключення вашого особистого бізнесу, веб-сайту, соціальних мереж тощо.</li>" +
      "<li>Відведення розмови про інші транспортні засоби або теми, не пов’язані з пропонованим транспортним засобом</li>" +
      "<li>Посилання на неприйнятні відео/веб-сторінки/вміст</li>" +
      "<li>Посилання на інші оголошення про продаж транспортних засобів</li>" +
      "</ul></div>",
  },
];

export const questionSeller = [
  {
    title: "Скільки коштує продати автомобіль на Carcool?",
    text:
      "Розміщення автомобіля на сайті Carcool абсолютно безкоштовне — і " +
      "продавці отримують 100% від кінцевої ціни продажу!",
  },
  {
    title: "Як ви обираєте, які автомобілі ви шукаєте?",
    text:
      "Ми зосереджені на хороших та справних автомобілях, з чистою " +
      "історією, відкритою інформацією, з реальним пробігом та без обтяжень " +
      "чи обмежень",
  },
  {
    title: "Чи можу я продати автомобіль, якщо живу за межами України? ",
    text:
      "Наразі Carcool пропонує лише автомобілі для в Україні. У майбутньому " +
      "ми можемо розглянути можливість додавання міжнародних продавців, " +
      "тому перевіряйте частіше!",
  },
  {
    title: "Як виставити автомобіль на продаж?",
    text:
      "Щоб виставити автомобіль на продаж, перейдіть за посиланням " +
      "«Продати автомобіль» у заголовку. Щоб продати свій автомобіль, вам " +
      "потрібно буде надати нам певну важливу інформацію, як-от марка, " +
      "модель, рік, VIN, фотографії та деякі інші важливі деталі.",
  },
  {
    title: "Яку інформацію мені потрібно надати, щоб продати свій автомобіль?",
    text:
      "Ми починаємо з основ: ми можемо повідомити вам, чи приймемо ваш " +
      "автомобіль, якщо ви просто надасте нам марку, модель, рік, VIN, кілька " +
      "фотографій та кілька інших деталей. Якщо ми приймемо ваш " +
      "автомобіль на основі цієї інформації, нам потрібно буде зібрати більше " +
      "деталей – ми запитаємо про вашу історію з автомобілем, " +
      "характеристики та інші елементи, які допомагають нам створити наш список.",
  },
  {
    title: "Як зробити найкращі фотографії мого автомобіля?",
    text:
      "Ми не можемо підкреслити, наскільки важливі фотографії для " +
      "успішного аукціону! Пам’ятаючи про це, відвідайте нашу сторінку " +
      "<a href='/PDF/photo_guide.pdf' target='_blank' rel='noopener noreferrer' class='linkBlack'>з фотографіями</a> , щоб отримати поради та підказки, завантажити наш " +
      "детальний посібник з фотографій або навіть найняти професійного " +
      "фотографа, який приїде зняти вашу машину",
  },
  {
    title: "Чи варто мені знімати відео?",
    text:
      "Так! Ми виявили, що зйомка відео, де детально описується зовнішній " +
      "стан, інтер’єр, моторний відсік, а також показує запуск і роботу двигуна, " +
      "дійсно викликає інтерес і підвищує довіру покупців. Це можна розбити " +
      "на кілька відео або все в одному.<br/>" +
      "Ми пропонуємо знімати в ландшафтному режимі, тримаючи телефон " +
      "горизонтально, а не вертикально.",
  },
  {
    title: "Чому я повинен пройти техогляд?",
    text:
      "Перевірка вашого автомобіля та надання учасникам торгів звіту про " +
      "перевірку дадуть вам найкращі гроші за ваш автомобіль. Коли " +
      "учасники торгів знають поточний стан автомобіля, у них є впевненість " +
      "робити ставки, причому більше. " +
      "<strong>Пам’ятайте, що одна додаткова ставка зазвичай оплачує всю вартість перевірки.<strong>",
  },
  {
    title: "Що охоплює перевірка?",
    text:
      "Перевірки проводяться ОПІНСПЕКТОР і включають деталі екстер’єру," +
      "салону та стану експлуатації автомобіля. Переглянути зразок акта " +
      "перевірки можна <a href='https://opinspector.com.ua/elementor-8494/' class='linkBlack'>тут</a>.<br/>" +
      "Додаткові відомості доступні" +
      "на https://opinspector.com.ua/pidbir-avto-kyiv/",
  },
  {
    title: "Чи буде інспектор тестувати мій автомобіль?",
    text:
      "Ми наполегливо рекомендуємо вам дозволити інспектору провести " +
      "тест-драйв вашого автомобіля в рамках перевірки. Інспектори " +
      "тестуватимуть транспортний засіб, якщо він дозволений на вулицях, " +
      "безпечний для керування, і ви дозволяєте їм керувати ним. У деяких " +
      "випадках інспектор може не мати змоги провести тест-драйв " +
      "транспортного засобу через обмеження розміру або проблеми з " +
      "відповідальністю. У таких випадках продавець або представник " +
      "продавця може пройти тест-драйв автомобіля.",
  },
  {
    title: "А якщо мені не сподобається перевірка?",
    text:
      "Вам не обов’язково додавати звіт про перевірку до свого оголошення. " +
      "Ми надішлемо вам електронний лист, коли звіт буде доступний, і ви " +
      "зможете вибрати, включити його чи ні. Ми завжди рекомендуємо " +
      "додавати звіт про перевірку, навіть із зазначеними недоліками, " +
      "оскільки учасники торгів почуватимуться впевненіше у вашому " +
      "автомобілі завдяки оцінці стану третьою стороною.<br/>" +
      "Зауважте, що якщо ви вирішите включити свій звіт про перевірку до " +
      "свого оголошення або повідомите будь-які деталі звіту про перевірку " +
      "нашій команді, ми зобов’язані включити всі виявлені недоліки, щоб " +
      "представити ваш автомобіль точно та чесно.",
  },
  {
    title: "Хто пише опис моєї машини?",
    text:
      "Після того як ви надасте нам всю необхідну інформацію про свій " +
      "автомобіль, ми можемо відкорегувати фактичний опис аукціону. Ми " +
      "його не запустимо без вашого схвалення, і ми докладаємо всіх зусиль, " +
      "щоб опис наших аукціонів був простим, об’єктивним і прямим, " +
      "зосереджуючись лише на найважливіших деталях, щоб допомогти " +
      "учасникам приймати рішення.",
  },
  {
    title: "Як зв'язатися з вами щодо мого оголошення?",
    text:
      "Ви матимете доступ до електронної пошти та зазвичай контакт менеджера.<br/>" +
      "Ми прагнемо відповідати на повідомлення чату якнайшвидше та завжди протягом одного робочого дня.",
  },
  {
    title: "Чи можу я запланувати початок аукціону мого автомобіля?",
    text:
      "Хоча ми не надаємо можливості точного планування аукціону, ми " +
      "дозволяємо нашим продавцям надавати переваги. Якщо ви віддаєте " +
      "перевагу певній даті початку або якщо ви віддаєте перевагу, щоб ваш " +
      "аукціон не проводився протягом певного тижня (наприклад, через " +
      "відпустку чи відрядження), повідомте нам, і ми зробимо все можливе щоб розмістити вас.",
  },
  {
    title: "Скільки триває аукціон?",
    text:
      "Усі аукціони тривають сім днів. Однак точна тривалість може " +
      "коливатися. Це тому, що протягом останньої хвилини аукціону кожна " +
      "нова ставка скидає годинник аукціону до однієї хвилини, що " +
      "залишилася. Це імітує традиційний аукціон, де торги тривають, доки учасники не зупиняться.",
  },
  {
    title: "Чи можу я редагувати свій аукціон, коли він опублікований?",
    text:
      "Ви не можете редагувати свій аукціон самостійно, але протягом усього " +
      "часу аукціону ви будете на зв’язку з Carcool. Якщо вам потрібно змінити " +
      "свій аукціон, просто зв’яжіться з нами та повідомте нам, що вам " +
      "потрібно змінити. Ви також можете залишати коментарі, які містять " +
      "додаткові фотографії, і ви можете відповідати на запитання, щоб покращити ясність вашого списку",
  },
  {
    title: "Як відповідати на запитання/як брати участь під час аукціону?",
    text:
      "Ми наполегливо рекомендуємо брати активну участь у розділі " +
      "коментарів і просимо вас відповідати на будь-які запитання, подані " +
      "там, у розділі запитань і відповідей вашого аукціону або безпосередньо " +
      "від користувачів через опцію «Зв’язатися з продавцем». Продавці, які " +
      "беруть активну участь, є найбільш успішними. Як завжди, позитивні та " +
      "конструктивні відповіді лише підвищать довіру з боку потенційних учасників торгів.<br/>" +
      "Майте на увазі, що покупець є автолюбителем, як і ви, і він, швидше за " +
      "все, не запитав би, якщо він серйозно не зацікавлений у вашому автомобілі!",
  },
  {
    title: "Чи можу я встановити резервну ціну на моєму аукціоні?",
    text:
      "Так, ми пропонуємо продавцям вибір між «резервним» і " +
      "«безрезервним» аукціоном. «Резерв» — це мінімальна ціна, яку " +
      "продавець готовий прийняти за автомобіль, яка невідома покупцям під " +
      "час аукціону. Аукціон «без резерву» не має мінімальної ціни, тобто " +
      "автомобіль буде продано за суму високої ставки.<br/>" +
      "Якщо ви виберете резервний аукціон, ми попросимо вас запропонувати " +
      "резервну ціну, але ми можемо попросити нижчу, перш ніж виставити " +
      "автомобіль на продаж. Якщо ми не можемо домовитися про резервну " +
      "ціну, без прикростей – звичайно, ви можете продати машину в іншому місці.<br/>" +
      "Якщо пізніше ви вирішите скоригувати свій резерв, ви можете" +
      "зменшити його в будь-який момент аукціону. Просто клацніть" +
      "піктограму «Нижчий резерв» на сторінці свого аукціону та введіть свій" +
      "новий резерв. Звичайно, ми дозволяємо продавцям використовувати" +
      "цей інструмент лише для зниження резервної ціни, тому новий резерв," +
      "який ви вводите, має бути нижчим за поточну резервну ціну.",
  },
  {
    title: "Чи повинен я розкривати свою резервну ціну?",
    text:
      "Ми рекомендуємо ніколи не розкривати свій резерв, особливо в " +
      "публічних коментарях або розділах із запитаннями та відповідями. Ми " +
      "виявили, що розкриття резервної ціни майже завжди сповільнює " +
      "ставки, особливо коли аукціон наближається або досягає цієї вартості.",
  },
  {
    title: "Хто може робити ставки на мій автомобіль?",
    text:
      "Усі, хто зареєструвався для участі в торгах на Cacool. Вони повинні " +
      "мати підтверджену електронну адресу та дійсну банкывську картку. " +
      "Очікується, що всі учасники торгів сплатять повну суму протягом " +
      "тижня після дати закриття аукціону.",
  },
  {
    title: "Чи можна спробувати укласти угоду поза аукціоном?",
    text:
      "Ні – транспортні засоби, представлені на сайті Carcool, продаються " +
      "виключно на аукціоні. Якщо покупець або продавець спробує укласти " +
      "угоду, намагаючись зірвати аукціон, ми назавжди забанимо вас на " +
      "сайті. Натомість, якщо ви хочете купити автомобіль на сайті Carcool, " +
      "просто поставте максимальну ставку, яку готові заплатити – це " +
      "найкращий спосіб гарантувати, що ви отримаєте потрібний вам автомобіль.",
  },
  {
    title: "Що робити, якщо мій резерв не буде виконано?",
    text:
      "Якщо ваш резерв не буде виконано, ми зв’яжемося з вами та " +
      "учасником аукціону, який запропонує високу ставку, і спробуємо " +
      "домовитися про ціну, яка задовольнить обидві сторони. Якщо ми " +
      "досягнемо згоди, ми зв’яжемо вас і покупця – і ми знизимо нашу комісію покупця до 2,5%.<br/>" +
      "Лише той, хто запропонує найвищу ставку, має право укласти угоду " +
      "після аукціону. Учасникам пропонується робити настільки високі " +
      "ставки, наскільки їм зручно під час аукціону, оскільки це найкращий " +
      "спосіб гарантувати, що вони отримають автомобіль, який вони хочуть.",
  },
  {
    title: "Як покупець зв'яжеться зі мною та здійснить оплату?",
    text:
      "Після завершення аукціону угода укладається між покупцем і " +
      "продавцем. Carcool надає покупцеві та продавцю контактну " +
      "інформацію один одного, і обидві сторони можуть завершити операцію. " +
      "Докладніше про <a href='/' class='linkBlack'>завершення транзакції</a>.",
  },
  {
    title: "Що робити, якщо учасник, який переміг, не здійснить покупку?",
    text:
      "Дуже рідко трапляється, що учасник, який виграв аукціон, не " +
      "дотримується Carcool. У незвичайному випадку, коли це трапляється, " +
      "переможець тендеру втрачає свої 4,5% комісії покупця — і йому " +
      "назавжди заборонено участь у Carcool.<br/>" +
      "Якщо ваш покупець не впорається, ми негайно співпрацюватимемо з " +
      "наступним(-ими) учасником(ами), щоб спробувати продати ваш " +
      "автомобіль за погодженою ціною. У випадку, якщо ми не можемо " +
      "укласти угоду, ми можемо запропонувати безкоштовно " +
      "перереєструвати ваш автомобіль або виплатимо вам половину зібраної " +
      "винагороди покупця через 90 днів.",
  },
  {
    title: "Як довго ви залишаєте минулі аукціони доступними для перегляду?",
    text:
      "Ми тримаємо всі аукціони постійно доступними для всіх, незалежно від " +
      "їх результатів. Ми робимо це, тому що вважаємо важливим бути " +
      "прозорими, щоб зміцнити довіру серед спільноти ентузіастів.",
  },
];

export const questionsRegistration = [
  {
    title: "Як я можу увійти?",
    text:
      "Ви можете ввійти, використовуючи адресу електронної пошти та " +
      "пароль. Просто натисніть кнопку «Зареєструватися» або «Увійти» у " +
      "верхньому правому куті екрана. Якщо ви бачите екран реєстрації, " +
      "натисніть посилання «Увійти тут», щоб перейти до входу. Там ви " +
      "можете ввести свою електронну адресу та пароль або вибрати " +
      "платформу входу, яку ви прив’язали до свого облікового запису.",
  },
  {
    title: "Навіщо вам моя електронна адреса?",
    text:
      "Усім користувачам необхідно вказати адресу електронної пошти. Це " +
      "адреса, на яку ви отримуватимете сповіщення про збережений пошук, " +
      "повідомлення від нашої команди про ваші аукціони чи ставки, а також " +
      "адресу, яка надається іншій стороні, коли ви виграєте аукціон абопродасте свій автомобіль.",
  },
  {
    title: "Що робити, якщо я забув пароль?",
    text:
      "Натисніть кнопку «Зареєструватися» або «Увійти» у верхньому правому " +
      "куті екрана. Якщо ви бачите екран реєстрації, натисніть посилання " +
      "«Увійти тут», щоб перейти до входу. У нижній частині екрана натисніть " +
      "«Забули пароль?» посилання. Введіть адресу електронної пошти свого " +
      "облікового запису, і ми надішлемо вам посилання для встановлення " +
      "нового пароля. Переконайтеся, що у вас є доступ до облікового запису " +
      "електронної пошти, оскільки це посилання діє через 24 години після " +
      "того, як ми його вам надішлемо.",
  },
  {
    title: "Як увійти за допомогою Google?",
    text:
      "Увійдіть за допомогою Google, натиснувши кнопку «Продовжити з " +
      "Google». Якщо у вас уже є обліковий запис Carcool, переконайтеся, що " +
      "ваш обліковий запис Google має ту саму електронну адресу, що й " +
      "електронна адреса, яку ви використовуєте для входу в Cars & Bids. Ви " +
      "не можете пов’язати обліковий запис Google зі своїм обліковим " +
      "записом Carcool, якщо адреси електронної пошти не збігаються.",
  },
];

export const questionsAboutDelivery = [
  {
    title: "Чи можу я змінити або скасувати доставку після замовлення?",
    text: "text",
  },
  {
    title: "Як мені зареєструватися для участі в торгах?",
    text: "Щоб зареєструватися, спочатку зареєструйтеся, натиснувши піктограму «Увійти» у верхньому правому куті екрана, а потім натисніть «Зареєструватися тут» у вікні, яке з’явиться згодом. Після створення імені користувача та пароля вам буде запропоновано підтвердити адресу електронної пошти. Після того, як ви це зробите, ви можете повернутися до Carcool, і вам буде запропоновано зареєструватися для участі в ставках.<br/> <br/> Якщо ви ще не хочете реєструватися, не біда – ви можете зробити це пізніше. Коли ви знайдете автомобіль, на який хочете зробити ставку, натисніть «Зробити ставку» на сторінці списку автомобіля, і вам буде запропоновано зареєструватися, перш ніж ви зможете зробити ставку. Вам доведеться ввести свою банківську карту та паспортні дані, оскільки ми заморожуємо частину коштів картки кожного учасника аукціону до завершення аукціону.",
  },
  {
    title: "Чому вам потрібна моя повна адреса, щоб замовити доставку?",
    text: "text",
  },
  {
    title: "У мене проблема з доставкою, до кого мені звернутися?",
    text: "text",
  },
];
