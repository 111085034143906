import React, { createContext, useContext } from "react";

const SignalRUserContext = createContext(null);

export const useSignalRUser = () => useContext(SignalRUserContext);

export const SignalRUserProvider = ({
  connection,
  newNotifiation,
  children,
}) => {
  return (
    <SignalRUserContext.Provider value={{ connection, newNotifiation }}>
      {children}
    </SignalRUserContext.Provider>
  );
};
