import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import styles from "./NavProfile.module.css";
import TextBlock from "../UI/TextBlock";
import { useSignalRUser } from "../Contexts/SignalRUserContext";

const NavProfile = ({ userId }) => {
  const [countBids, setCountBids] = useState("");
  const { connection } = useSignalRUser();

  const callGetCountBids = useCallback(async () => {
    try {
      if (connection?.state === "Connected") {
        await connection.invoke("GetCountBids", userId);
      } else {
        console.error("SignalR connection is not in the 'Connected' state.");
      }
    } catch (error) {
      console.error("Error calling GetCountBids:", error);
    }
  }, [connection, userId]);

  useEffect(() => {
    if (userId) {
      callGetCountBids();
    }
  }, [userId, connection, callGetCountBids]);

  useEffect(() => {
    const handleReceiveCountBids = (message) => {
      setCountBids(message?.сountBids);
    };

    connection?.on("ReceiveCountBids", handleReceiveCountBids);

    return () => {
      connection?.off("ReceiveCountBids", handleReceiveCountBids);
    };
  }, [connection]);

  return (
    <TextBlock color="#dbf6fa" className={`cardText ${styles.navProfile}`}>
      <ul className="d-flex justify-content-between flex-wrap">
        <li>
          <NavLink tag={Link} to=".">
            <strong>Профіль</strong>
          </NavLink>
        </li>
        <li>
          <NavLink tag={Link} to="announcements">
            <strong>Мої оголошення</strong>
          </NavLink>
        </li>
        <li>
          <NavLink tag={Link} to="bids">
            <strong>Мої ставки</strong>{" "}
            {countBids !== 0 && (
              <span className={`${styles.badget} badge`}>{countBids}</span>
            )}
          </NavLink>
        </li>
        <li>
          <NavLink tag={Link} to="chosenonce">
            <strong>Мої обрані</strong>
          </NavLink>
        </li>
        <li>
          <NavLink tag={Link} to="settings">
            <strong>Налаштування</strong>
          </NavLink>
        </li>
      </ul>
    </TextBlock>
  );
};

export default NavProfile;
