import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import { dataAuthCarousel } from "../../data/dataAuthCarousel";
import styles from "./AuthCarousel.module.css";

function carcoolReviewsAddKey(reviews) {
  return reviews.map((review, index) => ({
    ...review,
    key: index + 1,
  }));
}

const AuthCarousel = (args) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const items = carcoolReviewsAddKey(dataAuthCarousel);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
        className={styles.carouselItem}
      >
        <img
          src={item.src}
          alt={item.altText}
          className={styles.carouselImage}
        />
        <div className={styles.carouselContent}>
          <h4>{item.altText}</h4>
          <p className={styles.carouselItemText}>{item.caption}</p>
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={10000}
      {...args}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
        className={styles.carouselIndicatorsAuth}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default AuthCarousel;
