import React from "react";
import { useLocation } from "react-router-dom";
import Identification from "../../UI/Identification";

const ResetPasswordConfirmation = () => {
  const location = useLocation();
  const header = location.state?.header || "Успішно змінено пароль";
  const contextText =
    location.state?.contextText ||
    "Пароль успішно змінено, ви можете закрити цю сторінку.";

  return (
    <Identification
      header={header}
      contextText={contextText}
      hideGoogleButton={true}
    />
  );
};

export default ResetPasswordConfirmation;
