import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./components/Contexts/AuthContext";

const ProtectedRoute = ({ children, requiredRoles }) => {
  const { user, isAuthenticated, loading } = useAuth();

  if (loading) {
    return null;
  }

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  if (requiredRoles && (!user || !requiredRoles.includes(user?.role))) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
