import React from "react";
import { ReactComponent as IconClose } from "../../assets/icons/x.svg";
import { ReactComponent as Icon } from "../../assets/icons/clock.svg";
import { formatDistanceToNow } from "date-fns";
import { uk } from "date-fns/locale";
import styles from "./NotificationsDropdown.module.css";

const NotificationItem = ({ notification, onRemove, onMarkAsRead }) => {
  const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { locale: uk, addSuffix: true });
  };

  const handleClick = () => {
    onMarkAsRead(notification.id);
  };

  const handleRemoveClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove(notification.id);
  };

  return (
    <div className={styles.linkNotification} onClick={handleClick}>
      <div
        className={styles.notificationItem}
        style={{
          background: notification.isReviewed
            ? "inherit"
            : "var(--background-blue)",
        }}
      >
        <div className="pe-2">
          <span className={styles.iconNoteition}>
            <Icon />
          </span>
        </div>
        <div className="flex-grow-1 d-flex align-items-center justify-content-between">
          <div>
            <p className={styles.title}>{notification.title}</p>
            <span
              className={styles.message}
              dangerouslySetInnerHTML={{ __html: notification.description }}
            ></span>
            <p className={styles.date}>{formatTimeAgo(notification.created)}</p>
          </div>
          <button className={styles.removeButton} onClick={handleRemoveClick}>
            <IconClose />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
