import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CarTable from "../UI/CarTable";
import CarDescription from "../UI/CarDescription";
import Gallery from "../UI/Gallery";
import CarTitle from "../UI/CarTitle";
import Timer from "../UI/Timer";
import styles from "./CardDeteils.module.css";
import Chat from "../Chat/Chat";
import BidButton from "../UI/BidButton";
import useSignalRForLot from "../Hooks/useSignalRForLot";
import { useAuth } from "../Contexts/AuthContext";
import { useToast } from "../Contexts/ToastContext";
import useFetchData from "../Hooks/useFetchData";
import RequestType from "../Models/RequestType";
import FavoritesButton from "../UI/FavoritesButton";
import Loading from "../UI/Loading";
import ModalWinIsFirstBid from "../UI/ModalWinIsFirstBid";

const CardDeteils = () => {
  const [car, setCar] = useState({});
  const [timerStyle, setTimerStyle] = useState("");
  const { showToast } = useToast();
  const { id } = useParams();
  const [lotAuctionData, setLotAuctionData] = useState({});
  const [lotWinner, setLotWinner] = useState({});
  const [isFirstBid, setIsFirstBid] = useState(false);
  const [lotEnd, setLotEnd] = useState(false);
  const [messageCount, setMessageCount] = useState();
  const [timerExpired, setTimerExpired] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const previousLotID = useRef(car.lotID);
  const navigate = useNavigate();
  const location = useLocation();

  const connectionRef = useSignalRForLot(
    id,
    setLotAuctionData,
    setLotWinner,
    setIsFirstBid
  );

  const { user, isAuthenticated } = useAuth();

  const { fetchData: fetchCarDetails } = useFetchData(
    `Auction/GetDetailsLot/${id}`
  );
  const { fetchData: fetchFavoritesData } = useFetchData(
    isAuthenticated() ? "Auction/GetFavorites" : null
  );
  const { fetchData: fetchFavorite } = useFetchData(
    isFavorite
      ? "FavoriteLots/RemoveFavoriteLot"
      : "FavoriteLots/AddFavoriteLot",
    RequestType.POST
  );

  const mainPhotoSource = car?.mainPhoto?.source || "";
  const imagesSources = car?.images
    ? car.images.map((image) => image.source)
    : [];
  const allSources = [mainPhotoSource, ...imagesSources];

  const carTitle = {
    productionYear: car?.productionYear || "N/A",
    brand: car?.brand || "N/A",
    model: car?.model || "N/A",
    body: car?.body || "N/A",
    drive: car?.drive || "N/A",
    engine: car?.engine || "N/A",
    gearbox: car?.gearbox || "N/A",
    fuelType: car?.fuelType || "N/A",
  };

  const carTable = {
    brand: { value: car?.brand || "N/A" },
    model: { value: car?.model || "N/A" },
    mileage: car?.mileage || "N/A",
    vin: car?.vin || "N/A",
    region: { value: car?.region || "N/A" },
    ownerName: car?.ownerName || "N/A",
    engine: car?.engine || "N/A",
    fuelType: { value: car?.fuelType || "N/A" },
    drive: { value: car?.drive || "N/A" },
    gearbox: { value: car?.gearbox || "N/A" },
    body: { value: car?.body || "N/A" },
    color: car?.color || "N/A",
  };

  const carDescription = {
    carDescription: car.carDescription,
    ownerID: car.ownerID,
    ownerName: car.ownerName,
    completeSetOfCars: car.completeSetOfCars,
    modifiedList: car.modifiedList,
    disadvantagesList: car.disadvantagesList,
    serviceHistoryDescription: car.serviceHistoryDescription,
    serviceHistoryImages: car?.serviceHistoryImages
      ? car.serviceHistoryImages.map((image) => image.source)
      : [],
    ownerResponses: car.ownerResponses,
  };

  const difference = (timerEndTime) =>
    +new Date(timerEndTime || "") - +new Date();

  useEffect(() => {
    fetchCarDetails()
      .then((data) => {
        setCar(data);
        setIsFavorite(data.favorite);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [id, fetchCarDetails]);

  useEffect(() => {
    if (isAuthenticated()) {
      fetchFavoritesData()
        .then((data) => {
          const favoriteIds = data.map((item) => item.lotId);
          setIsFavorite(favoriteIds.includes(car.lotID));
        })
        .catch((err) => {
          console.error("Failed to fetch data:", err);
        });
    }
  }, [fetchFavoritesData, isAuthenticated, car.lotID]);

  useEffect(() => {
    if (!isAuthenticated()) {
      setIsFavorite(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const diff = difference(car?.timerEndTime || "");
    if (diff <= 0) {
      setTimerStyle("var(--dark-gray)");
      setLotEnd(true);
    } else if (diff / (1000 * 60 * 60) < 1) {
      setTimerStyle("var(--bs-red)");
    } else {
      setTimerStyle("var(--primary-blue)");
    }
  }, [car.timerEndTime]);

  useEffect(() => {
    if (previousLotID.current != null) {
      setCar((prevCar) => ({
        ...prevCar,
        countBid: lotAuctionData.bidCount,
        price: lotAuctionData.currentAmount,
      }));
    }
  }, [lotAuctionData]);

  useEffect(() => {
    if (car.lotID != null) {
      setCar((prevCar) => ({
        ...prevCar,
        countComments: messageCount,
      }));
    }
  }, [messageCount, car.lotID]);

  const callGetWinnerLot = async (lotId) => {
    if (connectionRef.current.state === "Connected") {
      try {
        if (timerExpired) return;

        await connectionRef.current.invoke("GetWinnerLot", lotId);

        setTimerExpired(true);
      } catch (error) {
        console.error("Error calling GetWinnerLot:", error);
      }
    } else {
      console.error("SignalR connection is not in the 'Connected' state.");
    }
  };

  const handleBid = async (event, isFirstBidUser = false) => {
    event.preventDefault();
    if (isAuthenticated()) {
      const userId = user.id;
      const lotId = car.lotID;
      setIsFirstBid(false);
      try {
        await connectionRef.current.invoke("PlaceBid", {
          lotId: lotId,
          userId: userId,
          isFirstBid: isFirstBidUser,
        });
      } catch (err) {
        showToast(
          "Помилка",
          `Виникла помилка при здійсненні ставки: ${err.message}`,
          "danger"
        );
      }
    } else {
      navigate("/login", { state: { from: location } });
    }
  };

  const handleFavoriteClick = useCallback(async () => {
    if (isAuthenticated()) {
      const favoriteLot = { LotId: id, UserId: user?.id };
      fetchFavorite(favoriteLot)
        .then((data) => {
          setIsFavorite((prevIsFavorite) => !prevIsFavorite);
          showToast("Успіх", data, "success");
        })
        .catch((err) => showToast("Помилка", err, "danger"));
    } else {
      navigate("/login", { state: { from: location } });
    }
  }, [
    id,
    isAuthenticated,
    fetchFavorite,
    showToast,
    navigate,
    location,
    user?.id,
  ]);

  const handleLinkClick = (event) => {
    if (!isAuthenticated()) {
      event.preventDefault();
      navigate("/login", { state: { from: location } });
    }
  };

  const changeStyle = (style) => {
    setTimerStyle(style);
  };

  const formattedNumber = (number) => {
    return typeof number === "number" ? number.toLocaleString("uk-UA") : "";
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const informSlideBar = () => {
    return (
      (car?.lotStatus === 2 ||
        car?.lotStatus === 3 ||
        car?.lotStatus === 4) && (
        <div className={` ${styles.informSlideBar}`}>
          <div className={styles.bar} style={{ background: `${timerStyle}` }}>
            <span>
              {!lotEnd && isEmptyObject(lotWinner) && "Часу лишилось: "}
              <Timer
                endDateTime={car?.timerEndTime || ""}
                changeStyle={changeStyle}
                onTimerEnd={() => {
                  if (!timerExpired) {
                    callGetWinnerLot(id);
                  }
                  setTimerExpired(true);
                }}
              />
            </span>
            <span>Ставка: ${formattedNumber(car?.price)}</span>
            <span>Ставок: {car?.countBid}</span>
            <span>Коментарів: {car?.countComments || "N/A"}</span>
            {!lotEnd && isEmptyObject(lotWinner) && (
              <span>Крок ставки: $ {car?.bidStep || "N/A"}</span>
            )}
            {lotEnd && !isEmptyObject(lotWinner) && (
              <span>
                {lotWinner?.userId && "Переможець: "} {lotWinner?.userName}
              </span>
            )}
          </div>
          {!lotEnd && isEmptyObject(lotWinner) && (
            <BidButton
              handleBid={handleBid}
              bidPrice={(car?.price ?? 0) + (car?.bidStep ?? 0)}
            />
          )}
        </div>
      )
    );
  };
  useEffect(() => {
    if (car.timerEndTime) {
      setTimerExpired(false);
    }
  }, [car.timerEndTime]);

  const checked = () => (
    <div className={`${styles.checked}`}>
      <a
        href="https://www.carvertical.com/ua"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={styles.carvertical}
          alt="Carvertical"
          src="/images/Carvertical.png"
        />
      </a>
      {car.checked && (
        <div className={styles.check}>
          {" "}
          <img alt="check" src="/images/magnifier_green.png" /> Перевірено!
        </div>
      )}
      <FavoritesButton
        isFavorite={isFavorite}
        handleFavoriteClick={handleFavoriteClick}
        isInCardDetails={true}
      />
    </div>
  );

  if (isEmptyObject(car)) {
    return <Loading />;
  }

  return (
    <div className="row">
      <div className="col-xxl-8 col-xl-12">
        <CarTitle title={carTitle} />
        <Gallery images={allSources} />
        {informSlideBar()}
        {checked()}
        <CarTable car={carTable} handleLinkClick={handleLinkClick} />
        <CarDescription
          car={carDescription}
          handleLinkClick={handleLinkClick}
        />
      </div>
      {(car?.lotStatus === 2 ||
        car?.lotStatus === 3 ||
        car?.lotStatus === 4) && (
        <div className="col-xxl-4 col-xl-12">
          <Chat lotId={car.lotID} setMessageCount={setMessageCount} />
        </div>
      )}
      {isFirstBid && (
        <ModalWinIsFirstBid
          isOpen={isFirstBid}
          handleBid={handleBid}
          setIsFirstBid={setIsFirstBid}
        />
      )}
    </div>
  );
};

export default CardDeteils;
