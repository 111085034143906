import React from "react";
import Select from "react-select";

const customStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "var(--dark-gray)" : "var(--dark-gray)",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--dark-gray)",
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "var(--primary-blue)" : "var(--light-gray)",
    color: state.isSelected ? "white" : "var(--text-color)",
    "&:hover": {
      background: "#0097b26b",
      color: "white",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "15px",
    border: "2px solid var(--primary-blue)",
    borderColor: state.isFocused
      ? "var(--primary-blue)"
      : "var(--primary-blue)",
    background: "var(--light-gray)",
    borderRadius: "14px",
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: "var(--primary-blue)",
    },
  }),
};

const DefaultSelect = ({ options, value, onChange }) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      styles={customStyles}
    />
  );
};

export default DefaultSelect;
