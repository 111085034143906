import CardDeteilsModeration from "./components/Moderation/CardDeteilsModeration";
import Brands from "./components/Pages/Admin/Brands";
import Models from "./components/Pages/Admin/Models";
import Users from "./components/Pages/Admin/Users";
import CardDeteils from "./components/Pages/CardDeteils";
import ExhibitingACar from "./components/Pages/ExhibitingACar";
import FAQ from "./components/Pages/FAQ";
import Home from "./components/Pages/Home";
import Authorize from "./components/Pages/Identification/Authorize";
import Registration from "./components/Pages/Identification/Registration";
import ForgotPassword from "./components/Pages/Identification/ForgotPassword";
import InModeration from "./components/Pages/InModeration";
import Profile from "./components/Pages/Profile";
import SellACar from "./components/Pages/SellACar";
import Announcements from "./components/ProfilePages/Announcements";
import Bids from "./components/ProfilePages/Bids";
import ChosenOnce from "./components/ProfilePages/ChosenOnes";
import Settings from "./components/ProfilePages/Settings";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import ForgotPasswordConfirmation from "./components/Pages/Identification/ForgotPasswordConfirmation";
import ResetPassword from "./components/Pages/Identification/ResetPassword";
import ResetPasswordConfirmation from "./components/Pages/Identification/ResetPasswordConfirmation";
import RegistrationConfirm from "./components/Pages/Identification/RegistrationConfirm";
import EmailConfirm from "./components/Pages/Identification/EmailConfirm";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import UseOfTerms from "./components/Pages/UseOfTerms";
import AboutCompany from "./components/Pages/AboutCompany";

const AppRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/PrivacyPolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/UseOfTerms",
    element: <UseOfTerms />,
  },
  {
    path: "/AboutCompany",
    element: <AboutCompany />,
  },
  {
    path: "/FAQ",
    element: <FAQ />,
  },
  {
    path: "/SellACar",
    element: <SellACar />,
  },
  {
    path: "/ExhibitingACar",
    element: (
      <ProtectedRoute>
        <ExhibitingACar />
      </ProtectedRoute>
    ),
  },
  {
    path: "/inModeration/:id",
    element: (
      <ProtectedRoute requiredRoles={["Moderator", "SuperAdmin"]}>
        <CardDeteilsModeration />
      </ProtectedRoute>
    ),
  },
  {
    path: "/inModeration",
    element: (
      <ProtectedRoute requiredRoles={["Moderator", "SuperAdmin"]}>
        <InModeration />
      </ProtectedRoute>
    ),
  },
  {
    path: "/CardDeteils/:id",
    element: <CardDeteils />,
  },
  {
    path: "/Brands",
    element: (
      <ProtectedRoute requiredRoles={["SuperAdmin"]}>
        <Brands />
      </ProtectedRoute>
    ),
  },
  {
    path: "/Models/:id",
    element: (
      <ProtectedRoute requiredRoles={["SuperAdmin"]}>
        <Models />
      </ProtectedRoute>
    ),
  },
  {
    path: "/Users",
    element: (
      <ProtectedRoute requiredRoles={["SuperAdmin"]}>
        <Users />
      </ProtectedRoute>
    ),
  },
  {
    path: "/Profile",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Profile />,
      },
      {
        path: "announcements",
        element: <Announcements />,
      },
      {
        path: "bids",
        element: <Bids />,
      },
      {
        path: "chosenonce",
        element: <ChosenOnce />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
];

export const AuthRoutes = [
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Authorize />
      </PublicRoute>
    ),
  },
  {
    path: "/registration",
    element: (
      <PublicRoute>
        <Registration />
      </PublicRoute>
    ),
  },
  {
    path: "/forgotpassword",
    element: (
      <PublicRoute>
        <ForgotPassword />
      </PublicRoute>
    ),
  },
  {
    path: "/forgotpasswordconfirmation",
    element: (
      <PublicRoute>
        <ForgotPasswordConfirmation />
      </PublicRoute>
    ),
  },
  {
    path: "/registrationconfirmation",
    element: (
      <PublicRoute>
        <RegistrationConfirm />
      </PublicRoute>
    ),
  },
  {
    path: "/emailconfirm",
    element: (
      <PublicRoute>
        <EmailConfirm />
      </PublicRoute>
    ),
  },
  {
    path: "/resetpassword",
    element: (
      <PublicRoute>
        <ResetPassword />
      </PublicRoute>
    ),
  },
  {
    path: "/resetpasswordconfirmation",
    element: (
      <PublicRoute>
        <ResetPasswordConfirmation />
      </PublicRoute>
    ),
  },
];

export default AppRoutes;
