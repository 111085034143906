import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import styles from "./ModalWinIsFirstBid.module.css";
import { Link } from "react-router-dom";

const ModalWinIsFirstBid = ({ isOpen, handleBid, setIsFirstBid }) => {
  const [isCommissionChecked, setIsCommissionChecked] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const handleSubmit = (e) => {
    if (!isCommissionChecked || !isPrivacyChecked) {
      setShowError(true);
      setTriggerAnimation(false);
      setTimeout(() => setTriggerAnimation(true), 10);
    } else {
      handleBid(e, true);
      setShowError(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsFirstBid(false)}
      contentClassName={styles.modalContent}
      centered
    >
      <ModalHeader
        toggle={() => setIsFirstBid(false)}
        className={styles.modalHeader}
      ></ModalHeader>
      <ModalBody className={styles.modalBody}>
        <div className="px-1 py-1 d-flex">
          <Input
            type="checkbox"
            id="commission"
            checked={isCommissionChecked}
            onChange={(e) => setIsCommissionChecked(e.target.checked)}
            className={`${styles.customInputFilter} mb-2 ${
              showError && !isCommissionChecked && triggerAnimation
                ? styles.error
                : ""
            }`}
          />
          <label htmlFor="commission" className={`${styles.label} ml-2`}>
            Я погоджуюсь сплатити комісію сервісу CarCool еквівалентну{" "}
            <strong>4.5%</strong> від фінальної ставки у разі виграшу лоту.
          </label>
        </div>
        <div className="px-1 py-1 d-flex">
          <Input
            type="checkbox"
            id="privatPolisy"
            checked={isPrivacyChecked}
            onChange={(e) => setIsPrivacyChecked(e.target.checked)}
            className={`${styles.customInputFilter} mb-2 ${
              showError && !isPrivacyChecked && triggerAnimation
                ? styles.error
                : ""
            }`}
          />
          <label htmlFor="privatPolisy" className={`${styles.label} ml-2`}>
            Я погоджуюсь з{" "}
            <Link to={"/UseOfTerms"} className="linkPrimary">
              умовами використання
            </Link>{" "}
            та{" "}
            <Link to={"/PrivacyPolicy"} className="linkPrimary">
              політикою конфіденційності
            </Link>{" "}
            CarCool.
          </label>
        </div>
      </ModalBody>
      <ModalFooter className={styles.modalFooter}>
        <Button color="success" onClick={(e) => handleSubmit(e)}>
          Зробити ставку
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default ModalWinIsFirstBid;
