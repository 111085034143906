import React, { useEffect, useState } from "react";
import styles from "../ProfilePages/ProfileContent.module.css";

const FormRowSetingsPhoneNumber = ({
  label,
  text = "",
  buttonText,
  inputType = "text",
  regex,
  onSave,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [previousValue, setPreviousValue] = useState("");
  const [error, setError] = useState(null);

  const countryCode = "+380";
  useEffect(() => {
    setInputValue(text ? text.slice(4) : "");
    setPreviousValue(text ? text.slice(4) : "");
  }, [text, setInputValue, setPreviousValue]);

  // Функція для форматування номера телефону для відображення
  const formatPhoneNumber = (value) => {
    const cleaned = value?.replace(/\D/g, "");
    const match = cleaned?.match(/^(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})$/);
    if (match) {
      let formattedNumber = "";
      if (match[1]) formattedNumber += `(${match[1]})`;
      if (match[1] && match[1].length === 2) formattedNumber += ` `;
      if (match[2]) formattedNumber += match[2];
      if (match[2] && match[2].length === 2) formattedNumber += `-`;
      if (match[3]) formattedNumber += match[3];
      if (match[4]) formattedNumber += `-${match[4]}`;
      return formattedNumber;
    }
    return value;
  };

  const handleChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "");
    setInputValue(rawValue);

    if (rawValue.length < 9 || rawValue.length > 10) {
      setError("Номер телефону має містити від 9 до 10 цифр");
    } else if (regex && !regex.test(rawValue)) {
      setError("Некоректний формат");
    } else {
      setError(null);
    }
  };

  const handleSave = () => {
    const rawValue = inputValue.replace(/\D/g, "");
    const fullValue = countryCode + rawValue;
    if (rawValue.length >= 9 && rawValue.length <= 10 && !error) {
      onSave(fullValue);
      setPreviousValue(inputValue);
      setShowInput(false);
    } else {
      setError("Номер телефону має містити від 9 до 10 цифр");
    }
  };

  const handleCancel = () => {
    setInputValue(previousValue);
    setShowInput(false);
    setError(null);
  };

  return (
    <div className="row mb-3">
      <div className="col-sm-8">
        <div className="form-group">
          <label className={styles.lableSetings}>
            <strong>{label}</strong>
          </label>
          <div className="d-flex align-items-center mt-3">
            {showInput ? (
              <div className="input-group">
                <span className={`input-group-text ${styles.phoneCode}`}>
                  {countryCode}
                </span>
                <input
                  type={inputType}
                  className={styles.formSetingsInput}
                  value={inputValue}
                  onChange={handleChange}
                  placeholder="Телефон"
                />
                {error && <span className={styles.errorText}>{error}</span>}
              </div>
            ) : (
              <p className={styles.formSetingsText}>
                {countryCode} {formatPhoneNumber(previousValue)}{" "}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-4 d-flex justify-content-end align-items-end">
        <>
          <button
            type="button"
            className={`float-right ${styles.btnPrimaryBlue}`}
            onClick={() => {
              if (showInput) handleSave();
              else setShowInput(true);
            }}
          >
            <strong>{showInput ? "Зберегти" : buttonText}</strong>
          </button>
          {showInput && (
            <button
              type="button"
              className={`float-right ${styles.btnPrimaryBlue}`}
              onClick={handleCancel}
              style={{ marginLeft: "10px" }}
            >
              <strong>Відмінити</strong>
            </button>
          )}
        </>
      </div>
    </div>
  );
};

export default FormRowSetingsPhoneNumber;
