import React, { createContext } from "react";

const ApiContext = createContext();

export const ApiProvider = ({ apiUrl, children }) => {
  return <ApiContext.Provider value={apiUrl}>{children}</ApiContext.Provider>;
};

export const useApi = (endpoint) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  return endpoint !== undefined ? `${apiUrl}/${endpoint}` : apiUrl;
};
