import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Popover,
  PopoverBody,
} from "reactstrap";
import Timer from "./Timer";
import FavoritesButton from "./FavoritesButton";
import { useAuth } from "../Contexts/AuthContext";
import styles from "./CardCar.module.css";
import useFetchData from "../Hooks/useFetchData";
import { useToast } from "../Contexts/ToastContext";
import RequestType from "../Models/RequestType";

const statusColors = {
  0: "var(--bs-yellow)",
  1: "var(--bs-orange)",
  2: "var(--primary-blue)",
  3: "var(--dark-gray)",
  4: "var(--bs-purple)",
  5: "var(--midl-gray)",
  6: "var(--saturated-green)",
  7: "var(--bs-red)",
  8: "var(--bs-danger)",
  9: "var(--bs-danger)",
  10: "var(--bs-danger)",
  11: "var(--bs-danger)",
};

const CardCar = ({
  id,
  index,
  image,
  title,
  text,
  location,
  price,
  reserv,
  checked,
  favoritesCar,
  toggleFavorite,
  endTime,
  lotStatus,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(favoritesCar);
  const [timerStyle, setTimerStyle] = useState("");
  const [statusStyle, setStatusStyle] = useState("");
  const targetId = `${index}_popover`;
  const handleMouseEnter = () => {
    setPopoverOpen(true);
  };

  const { user, isAuthenticated } = useAuth();
  const { showToast } = useToast();

  const { fetchData } = useFetchData(
    isFavorite
      ? "FavoriteLots/RemoveFavoriteLot"
      : "FavoriteLots/AddFavoriteLot",
    RequestType.POST
  );

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/CardDeteils/${id}`);
  };

  const changeStyle = (style) => {
    setTimerStyle(style);
  };

  useEffect(() => {
    setStatusStyle(statusColors[lotStatus.key] || "var(--default-color)");
  }, [lotStatus]);

  useEffect(() => {
    const difference = +new Date(endTime) - +new Date();
    if (difference <= 0) {
      setTimerStyle("var(--dark-gray)");
    } else if (difference / (1000 * 60 * 60) < 1) {
      setTimerStyle("var(--bs-red)");
    } else {
      setTimerStyle("var(--primary-blue)");
    }
  }, [endTime]);

  useEffect(() => {
    setIsFavorite(favoritesCar);
  }, [favoritesCar]);

  const handleMouseLeave = () => {
    setPopoverOpen(false);
  };

  const formattedNumber = (number) => {
    return typeof number === "number" ? number.toLocaleString("uk-UA") : "";
  };

  const handleFavoriteClick = useCallback(async () => {
    if (isAuthenticated()) {
      const favoriteLot = { LotId: id, UserId: user?.id };

      fetchData(favoriteLot)
        .then((data) => {
          setIsFavorite((prevIsFavorite) => !prevIsFavorite);
          if (toggleFavorite) {
            toggleFavorite(id);
          }
          showToast("Успіх", data, "success");
        })
        .catch((err) => {
          showToast("Помилка", err, "danger");
        });
    } else {
      navigate("/login");
    }
  }, [
    id,
    isAuthenticated,
    fetchData,
    showToast,
    toggleFavorite,
    navigate,
    user?.id,
  ]);

  return (
    <Card
      className={styles.cardCar}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <div className={styles.containerImage}>
        <img alt="Sample" src={image} className={styles.cardImg} />
      </div>
      {lotStatus.value !== 2 && lotStatus.value !== null && (
        <div className={styles.statusLotLaying}>
          <p
            className={`mb-0 ${styles.statusLot}`}
            style={{ background: `${statusStyle}` }}
          >
            {lotStatus.value}
          </p>
        </div>
      )}
      <div className={`d-flex justify-content-between ${styles.carLaying}`}>
        {(lotStatus.value === null || lotStatus.key === 2) && (
          <div
            className={`d-flex align-items-center ${styles.timerPrice}`}
            style={{ background: `${timerStyle}` }}
          >
            <p className="mb-0">
              <Timer endDateTime={endTime} changeStyle={changeStyle} />
            </p>
            <img
              alt="Gavel"
              src="/images/Gavel.png"
              className={styles.imgGavel}
            />
            <p className="mb-0">${formattedNumber(price)}</p>
          </div>
        )}
        {!reserv && (
          <div className={`d-flex align-items-center ${styles.noReserve}`}>
            <p className="mb-0">Без резерву</p>
          </div>
        )}
        {checked && (
          <>
            <img
              className={styles.imgFavorites}
              alt="magnifier_lightgreen"
              src="/images/magnifier_lightgreen.png"
              id={targetId}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            <Popover
              placement="bottom"
              isOpen={popoverOpen}
              target={targetId}
              toggle={() => {}}
            >
              <PopoverBody>Перевірене авто</PopoverBody>
            </Popover>
          </>
        )}
      </div>
      <CardBody className={styles.cardBody}>
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h5" className={styles.cardCarTitle}>
            {title}
          </CardTitle>
          {toggleFavorite && (
            <FavoritesButton
              isFavorite={isFavorite}
              handleFavoriteClick={handleFavoriteClick}
            />
          )}
        </div>
        <CardText className={styles.cardCarText}>{text}</CardText>
        <CardText className={styles.cardCarTextLocation}>
          <img
            alt="Obl"
            src="/images/point.png"
            className={styles.imgLocation}
          />
          {location}
        </CardText>
      </CardBody>
    </Card>
  );
};

export default CardCar;
