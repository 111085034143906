import React, { useState } from "react";
import { Table, Input } from "reactstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import styles from "./DataTable.module.css";
import DefaultSelect from "./DefaultSelect";

const DataTable = ({ data, columns, handleRowClick }) => {
  const [filterInput, setFilterInput] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
    state: { pageIndex },
    setGlobalFilter,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value);
  };

  const handlePageSizeChange = (selectedOption) => {
    setPageSize(selectedOption.value);
    setTablePageSize(selectedOption.value);
  };

  const pageNumbers = () => {
    const totalPages = pageOptions.length;
    const currentPage = pageIndex + 1;
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5) {
      if (currentPage <= 3) {
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  return (
    <div>
      <Input
        value={filterInput || ""}
        onChange={handleFilterChange}
        placeholder="Пошук..."
        style={{ marginBottom: "10px" }}
      />
      <Table {...getTableProps()} bordered>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    column.disableSort ? {} : column.getSortByToggleProps()
                  )}
                >
                  {column.render("Header")}
                  {!column.disableSort && (
                    <span className={styles.arrow}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " \u2193"
                          : " \u2191"
                        : ""}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  cursor:
                    row.cells[0].value === undefined || !handleRowClick
                      ? "default"
                      : "pointer",
                }}
                onClick={(event) => {
                  if (handleRowClick && row.cells[0].value !== undefined) {
                    handleRowClick(row.original.id, event);
                  }
                }}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div className={styles.pagination}>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Попередня
        </button>{" "}
        {pageNumbers().map((number) =>
          number !== pageIndex + 1 ? (
            <button
              key={number}
              onClick={() => gotoPage(number - 1)}
              className={number === pageIndex + 1 ? styles.currentPage : ""}
            >
              {number}
            </button>
          ) : (
            <span key={number} className={styles.currentPage}>
              {number}
            </span>
          )
        )}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Наступна
        </button>{" "}
        <span>
          Сторінка{" "}
          <strong>
            {pageIndex + 1} з {pageOptions.length}
          </strong>{" "}
        </span>
        <DefaultSelect
          options={[10, 20, 30, 40, 50].map((size) => ({
            value: size,
            label: `Відображати ${size}`,
          }))}
          value={{ value: pageSize, label: `Відображати ${pageSize}` }}
          onChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
};

export default DataTable;
