import React from "react";
import { Link } from "react-router-dom";

const NavFAQ = ({ elementRefs, howItWorksRefs }) => {
  const handleClick = (index, event) => {
    event.preventDefault();
    const header = document.getElementById("header");
    const headerHeight = header.getBoundingClientRect().height;
    if (index < elementRefs.length) {
      window.scrollTo({
        top: elementRefs[index].current.offsetTop - headerHeight - 20,
        behavior: "smooth",
      });
    } else if (
      index >= elementRefs.length &&
      index < elementRefs.length + howItWorksRefs.length
    ) {
      const howItWorksIndex = index - elementRefs.length;
      window.scrollTo({
        top:
          howItWorksRefs[howItWorksIndex].current.offsetTop - headerHeight - 20,
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="navFAQ">
      <ul>
        <li>
          <Link to="#" onClick={(event) => handleClick(1, event)}>
            <strong>ЯК ЦЕ ПРАЦЮЄ?</strong>
          </Link>
        </li>
        <li>
          <Link to="#" onClick={(event) => handleClick(6, event)}>
            <strong>Купівля автомобіля</strong>
          </Link>
        </li>
        <li>
          <Link to="#" onClick={(event) => handleClick(7, event)}>
            <strong>Продаж автомобіля</strong>
          </Link>
        </li>
        <li>
          <Link to="#" onClick={(event) => handleClick(8, event)}>
            <strong>Завершення продажу</strong>
          </Link>
        </li>
        <li>
          <Link to="#" onClick={(event) => handleClick(2, event)}>
            <strong>Покупець FAQ</strong>
          </Link>
        </li>
        <li>
          <Link to="#" onClick={(event) => handleClick(3, event)}>
            <strong>Продавець FAQ</strong>
          </Link>
        </li>
        <li>
          <Link to="#" onClick={(event) => handleClick(4, event)}>
            <strong>Поширені запитання щодо доставки</strong>
          </Link>
        </li>
        <li>
          <Link to="#" onClick={(event) => handleClick(5, event)}>
            <strong>Поширені запитання щодо реєстрації</strong>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavFAQ;
