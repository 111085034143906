import React, { useEffect, useRef } from "react";
import HowItWorks from "../FAQText/HowItWorks";
import AccordionList from "../UI/AccordionList";
import NavFAQ from "../FAQText/NavFAQ";
import {
  questionsBuyers,
  questionSeller,
  questionsAboutDelivery,
  questionsRegistration,
} from "../../data/dataAcardion";
import "./FAQ.css";
import { useSearchParams } from "react-router-dom";

function questionsAddId(questions) {
  return questions.map((question, index) => ({
    ...question,
    id: index + 1,
  }));
}

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const elementRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const howItWorksRefs = [useRef(null), useRef(null), useRef(null)];

  const [searchParams] = useSearchParams();
  const scrollToIndex = searchParams.get("scrollToIndex");

  useEffect(() => {
    const header = document.getElementById("header");
    const headerHeight = header?.getBoundingClientRect().height || 0;

    if (scrollToIndex !== undefined && !isNaN(scrollToIndex)) {
      let offsetTop = 0;

      if (scrollToIndex < elementRefs.length) {
        const elementRef = elementRefs[scrollToIndex];
        if (elementRef?.current) {
          offsetTop = elementRef.current.offsetTop;
        }
      } else if (
        scrollToIndex >= elementRefs.length &&
        scrollToIndex < elementRefs.length + howItWorksRefs.length
      ) {
        const howItWorksIndex = scrollToIndex - elementRefs.length;
        const elementRef = howItWorksRefs[howItWorksIndex];
        if (elementRef?.current) {
          offsetTop = elementRef.current.offsetTop;
        }
      }

      window.scrollTo({
        top: offsetTop - headerHeight - 20,
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToIndex]);

  return (
    <div className="row">
      <div className="col-xxl-4 col-xl-12" style={{ maxWidth: "300px" }}>
        <NavFAQ elementRefs={elementRefs} howItWorksRefs={howItWorksRefs} />
      </div>
      <div className="col-xxl-8 col-xl-12">
        <h1 className="title-no-line mt-4" ref={elementRefs[1]}>
          Як це працює
        </h1>
        <HowItWorks elementRefs={howItWorksRefs} />
        <h1 className="title-no-line mb-4">Питання що часто задаються</h1>
        <h4
          className="h4-margin-bottom"
          style={{ marginLeft: "25px" }}
          ref={elementRefs[2]}
        >
          <strong>Покупець FAQ</strong>
        </h4>
        <AccordionList questions={questionsAddId(questionsBuyers)} />
        <h4
          className="h4-margin-bottom"
          style={{ marginLeft: "25px", marginTop: "40px" }}
          ref={elementRefs[3]}
        >
          <strong>Продавець FAQ</strong>
        </h4>
        <AccordionList questions={questionsAddId(questionSeller)} />
        <h4
          className="h4-margin-bottom"
          style={{ marginLeft: "25px", marginTop: "40px" }}
          ref={elementRefs[4]}
        >
          <strong>Поширені запитання щодо доставки</strong>
        </h4>
        <AccordionList questions={questionsAddId(questionsAboutDelivery)} />
        <h4
          className="h4-margin-bottom"
          style={{ marginLeft: "25px", marginTop: "40px" }}
          ref={elementRefs[5]}
        >
          <strong>Запитання для реєстрації та входу</strong>
        </h4>
        <AccordionList questions={questionsAddId(questionsRegistration)} />
      </div>
    </div>
  );
};

export default FAQ;
