import React, { useEffect } from "react";
import TextBlock from "../UI/TextBlock";
import { Link } from "react-router-dom";

const colorBlue = "#dbf6fa";
const colorWhite = "#fff";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="row col-xxl-9 col-xl-12" style={{ margin: "auto" }}>
      <TextBlock color={colorWhite} className="cardText">
        <h2 className="h4-margin-bottom d-flex justify-content-center">
          <strong>Політика конфіденційності</strong>
        </h2>
        <p style={{ textIndent: "30px" }}>
          Будь ласка, ознайомтеся з нашою Політикою приватності (далі-Політика),
          щоб дізнатися які ваші персональні дані ми обробляємо, в яких цілях,
          як і на якій підставі.
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>1. Xто Розпорядник ваших персональних даних?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Собою, яка визначає цілі і засоби обробки Ваших персональних даних, є
          Товариство з обмеженою відповідальністю НАША ЮР ОСОБА компанія,
          створена і діє відповідно до законодавства України (далі -
          "Розпорядник" або "ми"). Розпорядник виконує обробку Ваших
          персональних даних, відповідно до Закону України від 1 червня 2010
          року № 2297-VI “Про захист персональних даних”, Регламенту (ЄС)
          2016/679 (GDPR) та цієї Політики.
        </p>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>
            2. Як зв’язатися з вашим менеджером із захисту персональних даних?
          </strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          З будь-яких питань, пов'язаних з обробкою Ваших персональних даних або
          реалізацією Ваших прав, будь ласка, звертайтеся за цією електронною
          адресою carcool365@gmail.com
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>3. Яка сфера дії політики?</strong>
        </h4>
        <ol>
          <li className="mb-3">
            Політика регулює обробку персональних даних всіх зовнішніх суб'єктів
            персональних даних, включаючи користувачів наших сайтів{" "}
            <Link to={"/"} className="linkBlack">
              продуктів, послуг та програм
            </Link>{" "}
            (далі разом: <strong>"Платформа"</strong>), кандидатів на
            працевлаштування, відвідувачів заходів, гостей офісів,учасників,
            менеджерів, бізнес-партнерів та інших суб'єктів персональних даних.
          </li>
          <li className="mb-3">
            Якщо Ви пропонуєте товари або послуги через Платформу і отримуєте
            доступ до персональних даних інших користувачів, як це описано в
            Розділі 10 Політики, ви також зобов'язуєтесь виконувати умови{" "}
            <Link to={"https://www.ria.com/uk/offert/sellers/"}>
              використання
            </Link>{" "}
            Carcool.
          </li>
          <li className="mb-3">
            Платформа не призначена для дітей молодше 16 років і ми свідомо не
            обробляємо їх персональні дані. Якщо Ви дізналися, що, незважаючи на
            будь-які заборони і обмеження з Вашої і нашої сторони, дитина
            використовує Платформу, будь ласка, повідомте нам і ми негайно
            припинимо обробку і видалимо всі її дані.
          </li>
          <li className="mb-3">
            Політика не регулює і ми не несемо відповідальності за обробку Ваших
            персональних даних третіми особами, які не перебувають під нашим
            контролем або не пов'язані з нами договірними відносинами, навіть
            якщо Ви отримали доступ до сайтів, товарів або послуг цих осіб за
            допомогою Платформи. Наприклад, Ви побачили рекламу будь-якої
            сторонньої компанії на Платформі і перейшли на її сайт або знайшли
            продавця на Платформі і його контакти, але зв'язалися з ним
            безпосередньо без нашої участі і в обхід Платформи або передали йому
            свої дані самостійно.
          </li>
        </ol>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>4. Які дані ми отримуємо від вас?</strong>
        </h4>
        <ol>
          <li className="mb-3">
            Надаючи нам доступ до персональних даних, Ви гарантуєте їх точність
            і достовірність, а також зобов'язуєтесь повідомляти нас про їх
            зміни.
          </li>
          <li className="mb-3">
            Ви несете відповідальність за будь-який збиток, заподіяний Платформі
            або особі, яка відповідає за Платформу, а також будь-якому іншому
            користувачеві або третій особі, в разі неточності або
            недостовірності Ваших персональних даних.
          </li>
          <li className="mb-3">
            У разі ненадання Вами необхідних для реєстрації або надання
            рекламних послуг даних, а також, в разі заперечення проти подальшої
            обробки або вимоги видалити персональні дані, ми, швидше за все, не
            можемо продовжувати надавати Вам наші послуги або залишатися в інших
            правовідносинах з вами.
            <p className="mt-3">
              Ми можемо отримати безпосередньо від Вас такі персональні дані:
            </p>
            <ul style={{ listStyleType: "none", paddingLeft: "30px" }}>
              <li className="mb-3">
                — прізвище, ім'я, по батькові, логін (далі - Ім'я);
              </li>
              <li className="mb-3">
                — контактні дані: номер телефону, email, (далі - Контактні
                дані);
              </li>
              <li className="mb-3">
                — облікові записи та / або сторінки соціальних мереж, які ви
                використовуєте для авторизації, пароль, номер телефону або логін
                (далі - Дані входу);
              </li>
              <li className="mb-3">
                — область, населений пункт, вулиця, будинок, квартира, індекс,
                відділення пошти (далі - Адреса);
              </li>
              <li className="mb-3">— Спосіб доставки;</li>
              <li className="mb-3">— Фотографії;</li>
              <li className="mb-3">
                — замовлення на товар, наші послуги, заявки на консультацію,
                дзвінок, перегляд (далі - Замовлення);
              </li>
              <li className="mb-3">— рекламні матеріали/оголошення;</li>
              <li className="mb-3">
                — будь-які дані, крім зазначених, які Ви надаєте в зверненні,
                відгуці, коментарі, скарзі, заявці або іншому запиті (далі -
                Дані запиту);
              </li>
              <li className="mb-3">
                — прізвище, ім'я, по батькові, серія і номер паспорта, ким і
                коли виданий, відомості про реєстрацію (далі - Паспортні дані);
              </li>
              <li className="mb-3">
                — реєстраційний номер облікової картки платника податків або ІПН
                (далі - Податковий номер);
              </li>
              <li className="mb-3">
                — чати на Платформі (далі - Історія спілкування);
              </li>
              <li className="mb-3">
                — будь-які дії на Платформі (далі - Історія користування);
              </li>
              <li className="mb-3">
                — унікальний набір букв і чисел (наприклад, ID546787), який ми
                присвоюємо при реєстрації (далі - Ідентифікатор).
              </li>
              <li className="mb-3">
                — IP-адреса, параметри обраного часового поясу, геолокація, тип
                пристрою, тип браузера, ідентифікатор сеансу (взаємодія з
                Платформою, доменне ім'я сайту, з якого Ви перейшли на
                Платформу, функції, які Ви використовуєте, і переглянуті
                сторінки Платформи, те, як Ви використовуєте Платформу, дату і
                час запиту, та дії, які Ви вживаєте, якщо такі дії присутні)
                (далі - Технічні дані).
              </li>
            </ul>
          </li>
          <li className="mb-3">
            Якщо Вам необхідно повідомити нам персональні дані або іншу
            інформацію про третіх осіб (вказати іншого одержувача товару,
            представити дані про поручителів і представників), в цьому випадку
            Ви зобов'язані самостійно отримати згоду у цих осіб на таку
            передачу. В іншому випадку, така передача даних заборонена.
            Завантаження на Платформу даних третіх осіб або їх фото- відео-
            зображень заборонена.
          </li>
          <li className="mb-3">
            В окремих випадках нам може знадобитися здійснити запит на додаткові
            персональні дані про Вас. В такому випадку ми направимо Вам окремий
            запит і повідомимо про мету збору і обробки.
          </li>
          <li className="mb-3">
            Також звертаємо увагу, що деякі Ваші дані будуть доступні іншим
            користувачам Платформи: розміщена Вами інформація, в тому числі
            оголошення, ім'я, фото профілю, контактні дані. Ми також включаємо
            Ваші рекламні матеріали/оголошення до складу розсилки по електронній
            пошті нашим підписникам. Ви можете вносити зміни в свої дані в
            особистому кабінеті.
          </li>
          <li className="mb-3">
            Коли Ви відвідуєте нашу Платформу, деякі дані збираються
            автоматично. Ми використовуємо для цього файли cookie та інші
            подібні інструменти. Детальніше.
          </li>
        </ol>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>5. Які дані ми отримуємо від третіх осіб?</strong>
        </h4>
        <ol>
          <li className="mb-3">
            1. У деяких випадках ми збираємо додатково інформацію про Вас з
            відкритих джерел (державних реєстрів) або від наших партнерів, а
            також на їх підставі можемо генерувати нові дані про Вас. Така
            інформація може включати:
            <ol className="mt-3">
              <li className="mb-3">
                дані про оплату, тобто статус оплати, маска платіжної картки,
                номер і дані замовлення - від платіжних організацій
              </li>
              <li className="mb-3">
                дані про нерухомість, тобто відомості про реєстрацію прав на
                нерухомість з державних реєстрів;
              </li>
              <li className="mb-3">
                дані про транспортний засіб:
                <ol className="mt-3">
                  <li className="mb-3">
                    з державних реєстрів - дані про реєстрацію прав на
                    транспортний засіб;
                  </li>
                  <li className="mb-3">
                    наявність або відсутність транспортного засобу в розшуку.
                  </li>
                  <li className="mb-3">
                    від наших партнерів (в тому числі) інспекторів і експертів
                    по запиту користувача про проведення перевірки:
                    <ul
                      style={{
                        listStyleType: "none",
                        paddingLeft: "30px",
                        marginTop: "1rem",
                      }}
                    >
                      <li className="mb-3">— дані про пробіг автомобіля;</li>
                      <li className="mb-3">
                        — дані про дорожньо-транспортні пригоди (ДТП), пов'язані
                        з конкретним автомобілем;
                      </li>
                      <li className="mb-3">
                        — інформація про марку, модель, тип, рік випуску, об'єм
                        двигуна, колір та тип палива транспортного засобу.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="mb-3">
            Конкретний набір оброблюваних про Вас персональних даних залежить
            від обраних Вами послуг, цілей обробки, способів взаємодії з нами, а
            також від Вашої активності на Платформі.
          </li>
          <li className="mb-3">
            Детальний опис цілей, підстав і строків обробок приведено в Розділі
            нижче.
          </li>
        </ol>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>6. Які цілі, підстави і терміни обробки ваших даних?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Ми можемо обробляти Ваші персональні дані для наступних цілей і на
          наступних правових підставах, в залежності від характеру наших
          відносин:
        </p>
        <ol>
          <li className="mb-3">
            Надання Вам послуг з користування Платформою та її сервісами на
            підставі умов використання Carcool тобто на підставі правочину з
            вами відповідно до ч.1.п.3 ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              {" "}
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1b) GDPR
            </Link>
            : умов використання Carcool
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви зареєстрований користувач Платформи, ми можемо обробляти
                Ваші Ім'я, контактні дані, замовлення, оголошення/рекламні
                матеріали, адресу, спосіб доставки, ідентифікатор, ваш аватар з
                соцмереж (якщо ви реєструвалися через соцмережі), технічні дані,
                дані з відкритих джерел або від наших партнерів в цілях
                реєстрації на Платформі, збереження даних входу та авторизації
                на конкретному пристрої, надання Вам можливості використовувати
                Платформу та її сервіси, надання іншим користувачам Платформи
                можливості знайти Ваші Оголошення та взаємодіяти з вами, обробки
                та оплати Замовлення, перевірки достовірності наданих Вами в
                Оголошенні відомостей, демонстрації результатів такої перевірки.
                <br />
                <p>
                  Ми будемо обробляти такі дані протягом 3 років після
                  припинення дії умов використання Carcool через видалення Вами
                  даних, облікового запису або направлення запиту на видалення
                  ваших персональних даних. Виняток становлять випадки, коли
                  більш тривалий термін зберігання даних встановлено чинним
                  законодавством, для захисту інтересів розпорядника.
                </p>
              </li>
              <li className="mb-3">
                Якщо Ви не зареєстрований користувач Платформи, ми можемо
                обробляти Ваші технічні дані, ідентифікатор, замовлення,
                оголошення, історію спілкування з метою надання Вам можливості
                використовувати Платформу та її послуги, надання іншим
                користувачам Платформи можливості знайти Ваші Оголошення і
                взаємодіяти з Вами, обробки і оплати Замовлення.
                <br />
                <p>
                  Ми будемо обробляти такі дані протягом 1 року з моменту Вашої
                  останньої активності.
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-3">
            Комунікації на Платформі і через Платформу на підставі законного
            інтересу Розпорядника відповідно до п.6 ч.1. ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              {" "}
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1f) GDPR
            </Link>
            :
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви комунікуєте з нами, покупцями, продавцями які розміщують
                інформацію на платформі з пропозиціями продажу нерухомості,
                транспортних засобів, товарів та послуг, оренди нерухомості,
                іншими користувачами Платформи, інспекторами, ріелторами,
                агентами, банками, експертами та іншими користувачами Платформи,
                ми можемо обробляти ім'я, контактні дані, технічні дані,
                ідентифікатор, дані запиту, податковий номер, Паспортні дані,
                Дані з відкритих джерел або від наших партнерів та Історію
                спілкування, з метою організації оперативного обміну
                інформацією, необхідною для здійснення угод на нашій Платформі,
                підтримки користувачів, розслідування порушень Умови
                користування Carcool врегулювання суперечок в рамках Платформи.
                <br />
                <p>
                  Ми будемо обробляти ці дані протягом 3 років після припинення
                  дії умов користування Carcool через видалення Вами облікового
                  запису або направлення запиту на видалення Ваших персональних
                  даних. Виняток становлять випадки, коли більш тривалий термін
                  зберігання даних встановлено чинним законодавством, для
                  захисту інтересів Розпорядника.
                </p>
              </li>
              <li className="mb-3">
                Якщо ми комунікуємо з Вами за допомогою телефонії, ми можемо
                записувати телефонні розмови з Вами з метою запобігання
                шахрайства, некоректної поведінки, розслідування правопорушень,
                а також з метою контролю якості обслуговування нашими
                фахівцями.Ми будемо зберігати ці записи протягом 1 року.
              </li>
            </ol>
          </li>
          <li className="mb-3">
            Маркетинг і PR Платформи на підставі вашої згоди відповідно до п.1
            ч.1. ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1a) GDPR
            </Link>
            :
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви відвідувач Платформи, ми можемо на підставі наданої Вами
                згоди обробляти ваші Технічні дані, Історію користування,
                Ідентифікатор з метою персоналізації контенту і таргетингу за
                допомогою аналітичних і маркетингових cookies. Ми будемо
                обробляти ці дані протягом строків, зазначених в нашій Політиці
                використання файлів cookie.
              </li>
              <li className="mb-3">
                Якщо Ви одержувач розсилки, то ми можемо обробляти Ваші
                Контактні дані, Ідентифікатор, Історію користування, а також
                аналітику Вашого профілю та вподобання для направлення Вам
                листів з нашими новинами, пропозиціями, персоніфікованим
                контентом та рекламою наших партнерів.
              </li>
            </ol>
            <p style={{ textIndent: "30px" }}>
              Ми будемо обробляти ці дані до моменту відкликання вами згоди.
            </p>
          </li>
          <li className="mb-3">
            4. Участь в заходах на підставі законного інтересу Розпорядника
            відповідно до п.6 ч.1. ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1f) GDPR
            </Link>
            :
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви відвідали або збираєтеся відвідати наш захід або брати
                участь в нашій конференції, ми можемо обробити Ваше Ім'я,
                Контактні дані, посаду, роботодавця, комунікативні та тематичні
                уподобання, спеціалізацію, професійний досвід, фотографії та
                відео (в межах, дозволених законом) з заходів, щоб запросити Вас
                та організувати Вашу участь в наших існуючих та майбутніх
                заходах / конференціях.
              </li>
            </ol>
            <p style={{ textIndent: "30px" }}>
              Ми будемо зберігати ці дані протягом 1 року після закінчення
              заходу.
            </p>
            <p style={{ textIndent: "30px" }}>
              Зверніть увагу, що ми маємо право публікувати групові фотографії
              та відео з наших заходів / конференцій на нашому веб-сайті, в
              профілі в соціальних мережах або інших каналах в маркетингових
              цілях.
            </p>
          </li>
          <li className="mb-3">
            Автоматичний аналіз даних на підставі легітимного інтересу
            Розпорядника відповідно до п.6 ч.1. ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1f) GDPR
            </Link>
            :
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви користувач Платформи, ми можемо автоматично обробляти і
                аналізувати Ваші Адресу, Замовлення, Оголошення, Історію
                користування, Ідентифікатор, Технічні дані, Дані про оплату і
                дані про перевірку з метою поліпшення Вашого користувацького
                досвіду і надання більш якісних послуг.
              </li>
            </ol>
          </li>
          <li className="mb-3">
            Збір даних з офіційних відкритих баз даних із застосуванням
            технології запобігання шахрайства, розвиток цієї технології на
            підставі легітимного інтересу Розпорядника відповідно до ч.1. п.6
            ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1f) GDPR
            </Link>
            :
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви користувач Платформи, ми можемо автоматично обробляти
                Ваші Оголошення та фотографії для зіставлення їх з даними з
                офіційних відкритих баз даних або наданих нашими партнерами та
                публікації додаткових відомостей про автомобілі в Вашому
                Оголошенні. Це дозволяє нашим користувачам швидко отримати повну
                достовірну інформацію про автомобіль з офіційних джерел.
              </li>
              <li className="mb-3">
                Якщо Ви завантажуєте фотографії про автомобіль, ми можемо
                використовувати їх для навчання технології штучного інтелекту,
                щоб автоматично зчитувати автомобільний номер або допомагати Вам
                замаскувати його.
              </li>
            </ol>
            <p style={{ textIndent: "30px" }}>
              Оскільки видалення таких даних спричинить порушення роботи
              алгоритму, ми продовжуємо зберігати ці фотографії аж до
              остаточного досягнення мети їх обробки і втрати необхідності в
              них. Ніхто, крім адміністратора алгоритму не має доступу до цих
              фотографій, ми нікому не передаємо і не продаємо їх. Ми видалимо
              фотографії Вашого автомобіля з цієї бази даних по Вашому запиту.
            </p>
          </li>
          <li className="mb-3">
            Виставлення рахунків і формування звітності на підставі необхідність
            виконання обов’язку Розпорядника персональних даних, який
            передбачений законом відповідно до п.5 ч.1. ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1c) GDPR
            </Link>
            :
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви оплачуєте наші послуги, ми можемо на виконання вимог
                податкового та бухгалтерського законодавства обробляти Ваші
                Ім'я, Замовлення, Ідентифікатор, Дані про оплату, Паспортні дані
                і Податковий номер в цілях виставлення Вам рахунків, формування
                і ведення звітності, зберігання даних для бухгалтерського та
                податкового обліку , надання даних для перевірок аудиторами та
                консультантами.
              </li>
            </ol>
            <p style={{ textIndent: "30px" }}>
              Ми будемо обробляти ці дані протягом строку, встановленого
              законодавством, але в жодному разі не довше 7 років.
            </p>
          </li>
          <li className="mb-3">
            Рекрутмент з метою підготовки до укладення контракту з Розпорядником
            відповідно до ч.1. п.5 ч.1 ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1b) GDPR
            </Link>
            :
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви кандидат на вакансію в компанії Розпорядника, ми можемо
                обробляти Ваше Ім'я, Контактні дані, резюме, відомості про
                досвід роботи, відомості про освіту, рекомендації, відгуки,
                результати тестів з метою призначення і проведення інтерв'ю,
                надання ваших даних потенційному керівникові, збереження Ваших
                даних в базі кандидатів для розгляду на інші вакансії.
              </li>
            </ol>
            <p style={{ textIndent: "30px" }}>
              Ми будемо зберігати Ваші дані до закінчення 3 місяців після
              проходження випробувального терміну, або протягом 6 місяців після
              відмови в працевлаштуванні.
            </p>
          </li>
          <li className="mb-3">
            Бізнес і партнерські відносини, на підставі законного інтересу
            Розпорядника відповідно до п.3 ч.1 ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1f) GDPR
            </Link>
            :
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви представник нашого поточного або потенційного
                бізнес-партнера, клієнта або постачальника, автор статей чи
                іншого контенту для Розпорядника, ми можемо обробляти Ваше Ім'я,
                Контактні дані, позицію, роботодавця, публічно доступну
                професійну інформацію про Вас, статті та інший контент для
                публікації, щоб вести з Вами переговори, погоджувати і
                підписувати документацію, повідомляти про прогрес і зміни в
                наших ділових відносинах, направляти наші пропозиції та новини.
              </li>
            </ol>
            <p style={{ textIndent: "30px" }}>
              Ми будемо обробляти ці дані протягом періоду наших ділових
              відносин з Вами або компанією, яку Ви представляєте.
            </p>
          </li>
          <li className="mb-3">
            Корпоративні та інвестиційні відносини на підставі формалізованого
            або неформального колективного договору з Вами відповідно до п.3 ч.1
            ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1b) GDPR
            </Link>
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви є поточним / потенційним директором або топ-менеджером
                Розпорядника або його афілійованих компаній, ми можемо обробляти
                Ваші Ім'я, Контактні дані, посаду, професійний досвід, освіту,
                володіння акціями або частками в компаніях, конфлікт інтересів,
                Паспортні дані, громадянство, банківські реквізити , розмір
                Вашої винагороди, дані про відрядження та представницькі витрати
                щодо організації Вашої участі в корпоративних зустрічах, заходах
                або програмах участі в капіталі, ділових поїздках, з метою
                виплати Вашої винагороди, покриття пов'язаних витрат, для
                ведення наших корпоративних записів, для обов'язкового
                публічного розкриття інформації та формування звітів, виконання
                запитів аудиторів або банків, уявлення Розпорядника в угодах, а
                також виконання інших юридичних зобов'язань. Ми також можемо
                ділитися цією інформацією всередині групи афілійованих компаній,
                надавати деякі з цих даних банкам, реєстраторам, нотаріусам,
                агентствам, аудиторам, перевіряючим державним органам,
                консультантам.
              </li>
              <li className="mb-3">
                Якщо Ви є поточним / потенційним акціонером, учасником або
                афілійованою особою Розпорядника, ми можемо обробляти Ваші Ім'я,
                Контактні дані, Паспортні дані, відомості про особовий рахунок,
                частку або тип, кількість акцій, кількість голосів, даних Вашого
                представника в цілях організації Вашої участі на корпоративних
                зборах і заходах, надання Вам матеріалів зустрічей і наших
                звітів, підтримки Вас в реалізації корпоративних прав, розкриття
                інформації та формування звітів, виконання запитів аудиторів і
                консультантів, виконання законних зобов'язань.
              </li>
            </ol>
            <p style={{ textIndent: "30px" }}>
              Ми будемо обробляти ці дані протягом строку дії корпоративного або
              трудового контракту з Вами, а після його припинення протягом 5
              років для цілей проходження перевірок та звітності.
            </p>
          </li>
          <li className="mb-3">
            Забезпечення безпеки на підставі законного інтересу Розпорядника
            відповідно до п.6 ч.1 ст.11{" "}
            <Link
              to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
              className="linkPrimary"
            >
              Закону України “Про захист персональних даних”
            </Link>{" "}
            та{" "}
            <Link
              to={"https://gdpr-text.com/uk/read/article-6/"}
              className="linkPrimary"
            >
              ст. 6 (1f) GDPR
            </Link>
            <ol className="mt-3">
              <li className="mb-3">
                Якщо Ви є відвідувачем, Користувачем Платформи, ми можемо
                обробляти завантажені вами персональні дані, Ім'я, Контактні
                дані, оголошення, Історію спілкування, Історію користування,
                Технічні дані, Паспортні дані, Податковий номер і інші
                персональні дані-в цілях забезпечення безпеки Платформи,
                модерації оголошень і повідомлень, запобігання шахрайства,
                захисту безпеки активів, прав та інтересів Розпорядника і третіх
                осіб, захисту прав інтелектуальної власності. Ця інформація може
                бути розкрита в цілях розслідування або судового розгляду на
                підставі офіційного запиту державного уповноваженого органу.
                <br />
                Ми маємо право продовжувати обробку зазначених у цьому пункті
                персональних даних протягом 3 років з моменту припинення дії
                умов використання Carcool через видалення Вами даних, облікового
                запису або отримання вимоги про видалення персональних даних,
                згідно з п.6 ч.1 ст.11{" "}
                <Link
                  to={"https://zakon.rada.gov.ua/laws/show/2297-17#Text"}
                  className="linkPrimary"
                >
                  Закону України “Про захист персональних даних”
                </Link>{" "}
                та{" "}
                <Link
                  to={"https://gdpr-text.com/uk/read/article-17/"}
                  className="linkPrimary"
                >
                  пп. (B) і (е) ст. 17 (3) GDPR
                </Link>{" "}
                з метою: захисту публічних інтересів всіх користувачів платформи
                від шахрайських та інших протиправних дій; запобігання помилок і
                дублювання оголошень на платформі; сприяння користувачам в
                захисті їх законних прав та інтересів, заяві, реалізації та
                оскарження їх законних вимог; пред'явленні позовів щодо
                розміщених оголошень і відомостей; сприяння уповноваженим
                державним органам у проведенні розслідувань за офіційними
                запитами; виконання обов'язків Розпорядника зі зберігання даних
                відповідно до чинного фінансового і податкового законодавства.
              </li>
              <li className="mb-3">
                Якщо Ви є відвідувачем офісу, ми можемо обробляти Ваше Ім'я,
                Паспортні дані, час входу-виходу, час, проведений в офісі,
                записи камер відеоспостереження та ім'я людини, яка вас
                запросила, відомості про температуру тіла, про наявність жару
                або поганого самопочуття, з метою контролю фізичного доступу та
                забезпечення безпеки в нашому офісі для запобігання, виявлення і
                розслідування будь-яких злочинів, неправомірних дій, інцидентів
                або нещасних випадків, а також з метою виконання приписів
                контролюючих органів по виявленню і запобіганню поширення
                епідемій. Ця інформація може бути передана третім сторонам з
                метою розслідування, судового переслідування, з метою
                страхування, надання невідкладної медичної допомоги.
              </li>
            </ol>
          </li>
        </ol>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>7. Чи здійснюємо ми автоматичну обробку даних?</strong>
        </h4>
        <ol>
          <li className="mb-3">
            Ми здійснюємо автоматичну обробку Ваших персональних даних на
            Платформі або в наших інформаційних системах.
          </li>
          <li className="mb-3">
            У нас є система модерації контенту, яка аналізує інформацію, що
            вводиться Вами, повідомлення, фотографії, відео - на предмет
            відповідності умов користування Carcool зокрема, на наявність
            ненормативної лексики, дублювання оголошень, зображення інших людей
            та інші заборонені чинники. Якщо система виявить порушення, Ваш
            обліковий запис або оголошення можуть бути заблоковані.
          </li>
          <li className="mb-3">
            Хоча ми постійно покращуємо систему автоматичної обробки, ми не
            можемо виключити повністю ситуації, коли система прийме неправильне
            рішення. Будь ласка, якщо Ви вважаєте, що була допущена помилка,
            зв'яжіться з нами.
          </li>
        </ol>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>8. Кому ми передаємо дані?</strong>
        </h4>
        <ol>
          <li className="mb-3">
            Для досягнення цілей, зазначених в цій Політиці, ми повинні
            забезпечити доступ до Ваших персональних даних нашим афілійованим
            компаніям, а також наступним третім особам, що допомагають нам в
            наданні послуг:
            <ol className="mt-3">
              <li className="mb-3">постачальникам технологічних послуг;</li>
              <li className="mb-3">
                партнерам і сервісним організаціям, що надають логістичні,
                туристичні, транспортні послуги та послуги з доставки;
              </li>
              <li className="mb-3">
                компаніям, що надають послуги, пов'язані з обслуговуванням
                клієнтів;
              </li>
              <li className="mb-3">
                партнерам і сервісним організаціям, чия діяльність пов'язана з
                рекламою і маркетингом, організацією заходів;
              </li>
              <li className="mb-3">
                партнерам, які надають офіційні відомості з відкритих баз даних;
              </li>
              <li className="mb-3">банківським організаціям;</li>
              <li className="mb-3">
                аудиторам, консультантам, перевіряючим державним органам.
              </li>
            </ol>
          </li>
          <li className="mb-3">
            Для виконання наших зобов'язань, передбачених законодавством, ми
            можемо передавати Ваші дані установам і службам, які займаються
            виявленням та запобіганням шахрайства, а також іншим уповноваженим
            державним органам (наприклад, податковим інспекціям).
          </li>
          <li className="mb-3">
            З метою ефективного надання послуг деякі з наших партнерів і
            підрядників розташовані за межами України в країнах, які
            забезпечують рівень захисту даних, відмінний від України, наприклад,
            країни Європейської економічної зони і Сполучені Штати Америки.
          </li>
          <li className="mb-3">
            Відносно всіх наших контрагентів ми застосовуємо договірні засоби
            захисту Ваших персональних даних і намагаємося забезпечити додаткові
            гарантії з їхнього боку, де це необхідно.
          </li>
          <li className="mb-3">
            Також ми можемо передавати Ваші дані іншим користувачам Платформи,
            але лише за Вашим вибором.{" "}
          </li>
          <li className="mb-3">
            Хоча ми встановлюємо додаткові гарантії для захисту Ваших
            персональних даних, але ми не можемо контролювати таких
            користувачів. Вони виступатимуть розпорядниками стосовно Ваших
            даних, які Ви їм передали безпосередньо або за допомогою нас.
          </li>
        </ol>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>9. Як продавці обробляють дані?</strong>
        </h4>
        <ol>
          <li className="mb-3">
            Для цілей цього розділу <strong>"Продавці"</strong> - фізичні особи,
            користувачі нашої Платформи, які розміщують на Платформі інформацію
            про товари або послуги, які вони пропонують іншим користувачам
            Платформи.
          </li>
          <li className="mb-3">
            Продавці є самостійними розпорядниками стосовно Ваших даних, тобто
            визначають цілі і засоби обробки Ваших даних самостійно.
          </li>
          <li className="mb-3">
            Ми передаємо дані конкретному Продавцю по Вашому запиту на підставі
            домовленості з Вами –{" "}
            <Link to={"https://www.ria.com/uk/offert/"} className="linkPrimary">
              умовами
            </Link>{" "}
            користування Carcool, а також договору з Продавцем.
          </li>
          <li className="mb-3">
            Залежно від Вашого запиту ми передаємо наступні дані Продавцю:
            <ul
              style={{
                listStyleType: "none",
                paddingLeft: "30px",
                marginTop: "1rem",
              }}
            >
              <li className="mb-3">— Ім'я;</li>
              <li className="mb-3">— Контактні дані;</li>
            </ul>
          </li>
          <li className="mb-3">
            Звертаємо Вашу увагу, що Продавці знаходяться в Україні.
          </li>
          <li className="mb-3">
            Для реалізації Ваших прав суб'єкта персональних даних в
            правовідносинах з Продавцем, Вам необхідно звернутися до нього
            безпосередньо. Якщо Вам недоступні його контакти або Продавець
            ігнорує Ваш запит, Ви можете звернутися до нас, і ми постараємося
            вам допомогти.
          </li>
        </ol>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>11. Як ми захищаємо Ваші дані?</strong>
        </h4>
        <ol>
          <li className="mb-3">
            Ми стежимо за розвитком технологій в області інформаційної безпеки
            та приватності, щоб максимально сучасними засобами обробляти та
            захищати Ваші персональні дані від несанкціонованого доступу, зміни,
            розкриття чи знищення. Ми регулярно впроваджуємо приватність по
            дизайну і по дефолту, щоб зробити приватність доступною для Вас
            автоматично. Крім того, для частини обробок ми використовуємо
            псевдонімізацію за допомогою унікального ідентифікатора користувача.
            Всі персональні дані зберігаються Розпорядником на захищених
            серверах в Європейському союзі, за винятком випадків залучення
            процесорів або інших розпорядників до обробки.
          </li>
          <li className="mb-3">
            Розпорядник обмежує будь-який доступ до Ваших персональних даних
            лише тим персоналом, процесорами та іншими Розпорядниками, яким
            необхідно обробляти ці дані для виконання посадових або договірних
            обов'язків. З ними підписані відповідні угоди про захист і
            конфіденційність Ваших даних, в яких передбачена відповідальність за
            порушення цих зобов'язань.
          </li>
          <li className="mb-3">
            Ми періодично проводимо перевірку і оцінюємо ризики процесів збору,
            зберігання, обробки персональних даних та заходів безпеки, які
            застосовуються Розпорядником.
          </li>
          <li className="mb-3">
            Ми також контролюємо і модеруємо дані на Платформі, щоб ефективно
            протидіяти будь-яким зловживанням для захисту сумлінних користувачів
            і захисту їх від порушення прав і свобод, а також від майнових
            втрат.
          </li>
          <li className="mb-3">
            Будь ласка, будьте уважні, в разі Вашого необережного ставлення до
            безпеки і захисту Ваших персональних даних, треті особи можуть
            отримати несанкціонований доступ до облікового запису та Ваших
            персональних даних, незважаючи на всі наші заходи захисту. В такому
            випадку ми не несемо відповідальності за збитки, завдані таким
            доступом Вам або третім особам.
          </li>
        </ol>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>12. Як ми оновлюємо дану політику?</strong>
        </h4>
        <ol>
          <li className="mb-3">
            Ми можемо періодично оновлювати справжню Політику, про що ми
            обов'язково повідомимо Вас по email або у формі спливаючих
            повідомлень на Платформі.
          </li>
        </ol>
      </TextBlock>
    </div>
  );
};

export default PrivacyPolicy;
