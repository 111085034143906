import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import PdfLink from "../UI/PdfLink";

const Footer = () => {
  return (
    <div className="container-fluid">
      <footer
        className={`${styles.footer} row row-cols-1 row-cols-sm-2 row-cols-md-5 py-3`}
      >
        <div className="col mb-3">
          <Link to="/">
            <img
              src="/images/CARCOOL.png"
              className={styles.logoFooter}
              alt="Logo"
            />
          </Link>
        </div>
        <div className={`${styles.colText} col mb-3`}>
          <p className="ms-5">ЯК ЦЕ ПРАЦЮЄ</p>
          <ul className="nav flex-column ms-5">
            <li>
              <Link to={`/FAQ?scrollToIndex=6`}>Купівля машини</Link>
            </li>
            <li>
              <Link to={`/FAQ?scrollToIndex=7`}>Продаж машини</Link>
            </li>
            <li>
              <Link to={`/FAQ?scrollToIndex=8`}>Завершення торгів</Link>
            </li>
            <li>
              <Link to="FAQ">FAQ</Link>
            </li>
          </ul>
        </div>
        <div className={`${styles.colText} col mb-3`}>
          <p className="ms-5">ПРОДАВЦЯМ</p>
          <ul className="nav flex-column ms-5">
            <li>
              <Link to={`/FAQ?scrollToIndex=7`}>Як надіслати своє авто</Link>
            </li>
            <li>
              <PdfLink URL={"/PDF/photo_guide.pdf"} children={"Гайд по фото"} />
            </li>
          </ul>
        </div>
        <div className={`${styles.colText} col mb-3`}>
          <p className="ms-5">КОРИСНІ ПОСИЛАННЯ</p>
          <ul className="nav flex-column ms-5">
            <li>
              <Link to={`/PrivacyPolicy`}>Політика конфіденційності</Link>
            </li>
            <li>
              <Link to={`/UseOfTerms`}>Умови використання</Link>
            </li>
          </ul>
        </div>
        <div className="col-md2 col-sm-2">
          <ul className="nav d-flex mt-2">
            <li className="me-3 mb-3">
              <Link to="https://www.instagram.com/carcool_official?igsh=ZnFibDQzbDJ1enUy&utm_source=qr">
                <img
                  src="/images/Instaggram.png"
                  className={styles.iconFooter}
                  alt="Instaggram"
                />
              </Link>
            </li>
            <li className="me-3 mb-3">
              <Link to="https://t.me/carcool_official">
                <img
                  src="/images/Telegram.png"
                  className={styles.iconFooter}
                  alt="Telegram"
                />
              </Link>
            </li>
            <li className="me-3 mb-3">
              <Link to="https://www.tiktok.com/@carcool_official?_t=8qjeZ4SMtf7&_r=1">
                <img
                  src="/images/X.png"
                  className={styles.iconFooter}
                  alt="X"
                />
              </Link>
            </li>
            <li className="me-3 mb-3">
              <Link to="https://www.facebook.com/share/V4WpeXBZKuLzNSQy/?mibextid=LQQJ4d">
                <img
                  src="/images/Facebook.png"
                  className={styles.iconFooter}
                  alt="Facebook"
                />
              </Link>
            </li>
          </ul>
          <div className={`${styles.colText} mt-2`}>
            <p className="text-xc-center">
              &copy;{new Date().getFullYear()} Copyright
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
