import React, { useState, useRef } from "react";
import styles from "../Pages/CardDeteils.module.css";

const BidButton = ({ handleBid, bidPrice }) => {
  const [tooltipPosition, setTooltipPosition] = useState("bottom");
  const buttonRef = useRef(null);

  const handleMouseEnter = () => {
    handlePosition();
  };

  const handlePosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const bottomDistance = windowHeight - rect.bottom;

      if (bottomDistance < rect.height + 10) {
        setTooltipPosition("top");
      } else {
        setTooltipPosition("bottom");
      }
    }
  };

  return (
    <div className={styles.tooltip}>
      <button
        ref={buttonRef}
        onClick={handleBid}
        onMouseEnter={handleMouseEnter}
        className={styles.bidButton}
      >
        Поставити!
      </button>
      <span className={`${styles.tooltiptext} ${styles[tooltipPosition]}`}>
        Поставити: <br /> $ {bidPrice}
      </span>
    </div>
  );
};

export default BidButton;
