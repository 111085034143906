import React from "react";

const PdfLink = ({ URL, children, className }) => {
  return (
    <a
      href={URL}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  );
};

export default PdfLink;
