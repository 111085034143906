import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavbarToggler,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { useAuth } from "../Contexts/AuthContext";
import useFetchData from "../Hooks/useFetchData";
import NotificationsDropdown from "./NotificationsDropdown";

const NavMenu = () => {
  const { isAuthenticated, user, logout } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userIconSource, setUserIconSource] = useState(null);
  const [userid, setUserid] = useState(user ? user?.id : null);

  const { fetchData: fetchUserIcon } = useFetchData(
    `Profile/GetUserIcon/${userid}`
  );

  useEffect(() => {
    if (user && isAuthenticated) {
      setUserid(user?.id);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (user) {
      fetchUserIcon()
        .then((data) => {
          setUserIconSource(data);
        })
        .catch((err) => console.error("Error fetching messages:", err));
    }
  }, [user, fetchUserIcon]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <header id="header">
      <Navbar
        className="navbar-expand-md container-fluid ng-white border-bottom box-shadow"
        light
      >
        <NavbarBrand tag={Link} to="/">
          <img src="/images/CARCOOL.png" className="logo" alt="Logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={isOpen} navbar>
          {isAuthenticated() ? (
            <>
              <NotificationsDropdown />
              <Dropdown
                nav
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className=""
                style={{ listStyle: "none", order: "2" }}
              >
                <DropdownToggle nav caret={false} className="nav-autorize">
                  <img
                    src={
                      userIconSource ? userIconSource : "/images/profile1.png"
                    }
                    alt="user"
                    className="avatartUser"
                  />{" "}
                  {user?.username}
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem tag={Link} to="/Profile">
                    Профіль
                  </DropdownItem>
                  {(user?.role === "SuperAdmin" ||
                    user?.role === "Moderator") && (
                    <>
                      <div className="dividingLine  mt-1 mb-1"></div>
                      <DropdownItem tag={Link} to="/inModeration">
                        Модерація
                      </DropdownItem>
                    </>
                  )}
                  {user?.role === "SuperAdmin" && (
                    <>
                      <div className="dividingLine mt-1 mb-1"></div>
                      <DropdownItem tag={Link} to="/Brands">
                        Управління Брендами
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/Users">
                        Управління Користувачами
                      </DropdownItem>
                    </>
                  )}
                  <div className="dividingLine mt-1 mb-1"></div>
                  <DropdownItem onClick={handleLogout}>Вийти</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          ) : (
            <NavLink tag={Link} className="nav-autorize" to="/Login">
              <img
                className="avatartUser"
                alt="Вхід"
                src="/images/profile-sigin.png"
              />
            </NavLink>
          )}
          <div className="navbar-collapse">
            <div className="d-flex flex-column flex-md-row flex-grow-1">
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">
                    Аукціон
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark outlineNavLink"
                    to="/SellACar"
                  >
                    Продати Авто
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/AboutCompany">
                    Про компанію
                  </NavLink>
                </NavItem>
              </ul>
            </div>
          </div>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default NavMenu;
