import React from "react";
import AboutUs from "../FAQText/AboutUs";

const AboutCompany = () => {
  return (
    <div class="row col-xxl-9 col-xl-12" style={{ margin: "auto" }}>
      <h1 className="title-with-line">Про нас</h1>
      <AboutUs />
    </div>
  );
};

export default AboutCompany;
