import { useRef, useEffect } from "react";
import createSignalRConnectionForLot from "../Services/createSignalRConnectionForLot";
import { useToast } from "../Contexts/ToastContext";

const useSignalRForLot = (
  lotId,
  setLotAuctionData,
  setLotWinner,
  setIsFirstBid
) => {
  const connectionRef = useRef(null);
  const { showToast } = useToast();

  useEffect(() => {
    const connection = createSignalRConnectionForLot(lotId);
    connectionRef.current = connection;

    connection.on("ReceiveUpdate", (lotUpdateModel) => {
      setLotAuctionData(lotUpdateModel);
    });

    connection.on("ReceiveLotWinner", (lotWinner) => {
      setLotWinner(lotWinner);
    });

    connection.on("ReceiveIsFirstBid", (isFirstBid) => {
      setIsFirstBid(isFirstBid.result);
    });

    connection.on("ReceiveInformation", (information) => {
      showToast(
        information.success ? "Успіх" : "Помилка",
        information.message,
        information.success ? "success" : "warning"
      );
    });

    connection.on("ReceiveError", (error) => {
      showToast("Помилка", error, "danger");
    });

    return () => {
      connection.stop();
    };
  }, [lotId, setLotAuctionData, setLotWinner, setIsFirstBid, showToast]);

  return connectionRef;
};

export default useSignalRForLot;
