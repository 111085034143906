const ConfirmRegistration = async (registrationModel, apiUrl) => {
  const registrationUrl = `${apiUrl}/AccountManager/registration`;
  let responseData = null;
  let error = null;

  try {
    const response = await fetch(registrationUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registrationModel),
    });

    if (!response.ok) {
      error = await response.text();
    } else {
      responseData = await response.json();
    }
  } catch (err) {
    error = err.message;
  }
  return { responseData, error };
};

const Login = async (signInModel, apiUrl) => {
  const signInUrl = `${apiUrl}/AccountManager/login`;
  let success = false;
  let message = null;

  try {
    const response = await fetch(signInUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signInModel),
    });
    if (response.ok) {
      success = true;
    } else {
      message = await response.text();
    }
  } catch (err) {
    message = err.message;
  }

  return { success, message };
};

const ForgotPassword = async (email, apiUrl) => {
  const forgetPasswordUrl = `${apiUrl}/AccountManager/forget-password`;
  let responseData = null;
  let error = null;

  try {
    const response = await fetch(forgetPasswordUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    });

    if (!response.ok) {
      error = await response.text();
    } else {
      responseData = await response.json();
    }
  } catch (err) {
    error = err.message;
  }
  return { responseData, error };
};

const ResetPassword = async (resetModel, apiUrl) => {
  const resetPasswordUrl = `${apiUrl}/AccountManager/reset-password`;
  let responseData = null;
  let error = null;

  try {
    const response = await fetch(resetPasswordUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(resetModel),
    });

    if (!response.ok) {
      error = await response.text();
    } else {
      responseData = await response.json();
    }
  } catch (err) {
    error = err.message;
  }
  return { responseData, error };
};

const VerifyToken = async (verifyModel, apiUrl) => {
  const verifyTokenUrl = `${apiUrl}/AccountManager/verify-reset-token`;
  let responseData = null;
  let error = null;

  try {
    const response = await fetch(verifyTokenUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(verifyModel),
    });

    if (!response.ok) {
      error = await response.text();
    } else {
      responseData = await response.json();
    }
  } catch (err) {
    error = err.message;
  }
  return { responseData, error };
};

const GoogleLogin = async (googleTokenId, apiUrl) => {
  const googleLoginUrl = `${apiUrl}/AccountManager/google-login`;
  let responseData = null;
  let error = null;

  try {
    const response = await fetch(googleLoginUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(googleTokenId),
    });

    if (!response.ok) {
      error = await response.text();
    } else {
      responseData = await response.json();
    }
  } catch (err) {
    error = err.message;
  }
  return { responseData, error };
};

const Logout = async (apiUrl) => {
  const logoutUrl = `${apiUrl}/AccountManager/logout`;

  const response = await fetch(logoutUrl, {
    method: "POST",
    credentials: "include",
  });

  if (response.ok) {
    //console.log("Logout successful");
  } else {
    console.error("Logout failed");
  }
};

export {
  ConfirmRegistration,
  Login,
  ForgotPassword,
  ResetPassword,
  VerifyToken,
  GoogleLogin,
  Logout,
};
