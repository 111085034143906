import React from "react";
import TextBlock from "../UI/TextBlock";
import { Link } from "react-router-dom";
import PdfLink from "../UI/PdfLink";

const colorBlue = "#dbf6fa";
const colorWhite = "#fff";

const HowItWorks = ({ elementRefs }) => {
  const [elementRef1, elementRef2, elementRef3] = elementRefs;

  return (
    <>
      <TextBlock color={colorWhite} className="cardText">
        <div ref={elementRef1}>
          <h4 className="h4-margin-bottom">
            <strong>Купівля автомобіля</strong>
          </h4>
          <p>
            Коли ви знайшли автомобіль, який вас цікавить, ось кроки, які вам
            слід зробити, щоб впевнено робити ставки та якщо пощастить, виграти
            аукціон!
          </p>
          <strong>1. Зареєструйтеся для участі в торгах</strong>
          <br />
          <p>
            Щоб зв'язатися безпосередньо з продавцем і зробити ставки, ви
            повинні спочатку зареєструватись для участі в торгах з дійсною
            банківською карткою та номером телефону. Переможці аукціону
            сплачують Carcool комісію покупця у розмірі 4,5% понад суму
            виграшної ставки (мінімум 90 у.о.).
          </p>
          <strong>2. Будьте обачні</strong>
          <p style={{ marginBottom: 0 }}>
            Незважаючи на те, що ми намагаємося зробити купівлю автомобіля в
            Інтернеті максимально безпечною та легкою, зрештою, ви зобов’язані
            провести власну належну перевірку та переконатися, що автомобіль,
            який ви розглядаєте, підходить саме вам – перш ніж робити ставку .
          </p>
          <ul>
            <li>
              Ретельно перегляньте список, включаючи відомі недоліки, звіт про
              історію транспортного засобу, звіт про перевірку транспортного
              засобу (якщо є), нещодавнє технічне обслуговування, фотографії
              тощо.
            </li>
            <li>
              Поставте продавцю будь-які запитання щодо транспортного засобу
              через коментарі, запитання й відповіді продавця.
            </li>
            <li>
              Домовтеся про особистий огляд транспортного засобу якщо він
              знаходиться на майданчику.
            </li>
          </ul>
          <strong>3. Ставка</strong>
          <p style={{ marginBottom: 0 }}>
            Ми спростили встановлення ставок і пояснили це в короткому
            відеопосібнику:
          </p>
          <ul>
            <li>
              Коли ви робите ставку, ми заморожуємо на вашій карті комісію
              покупця – якщо ви виграєте, з вашої картки буде стягнуто кошти в
              розмірі комісії і ви заплатите продавцю безпосередньо за
              автомобіль,в іншому разі заморозка буде знята після закінчення
              аукціону.
            </li>
            <li>
              Ставки є обов’язковими, тому робіть ставки, лише якщо ви повністю
              маєте намір придбати автомобіль і виконали необхідну належну
              перевірку, тому що ви можете опинитися власником найвищої ставки,
              а відшкодування не буде.
            </li>
            <li>
              Щоб гарантувати, що процес ставок буде справедливим для всіх,
              ставки, розміщені протягом останньої хвилини аукціону, скинуть
              час, що залишився до аукціону, до 1 хвилини, що дасть іншим
              можливість робити ставки.
            </li>
          </ul>
          <strong>4. Виграти аукціон</strong>
          <p>
            Щоб купити автомобіль на сайті Carcool, ви повинні виграти аукціон,
            ставши власником найвищої ціни, і, якщо на аукціоні є «Резерв»,
            поставивши ставку, яка відповідає або перевищує приховану «Резервну»
            ціну продавця. Якщо на аукціоні немає резерву, переможе той, хто
            запропонує найвищу ставку, незалежно від суми, яку він запропонує.
          </p>
          <p>
            Після завершення аукціону ми надамо вам покроковий контрольний
            список для завершення покупки. Ви також отримаєте контактну
            інформацію продавця (і навпаки), щоб завершити деталі та логістику
            транзакції. Очікується, що покупці сплатять за автомобіль повністю
            протягом тижня після закриття аукціону.
          </p>
        </div>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <div ref={elementRef2}>
          <h4 className="h4-margin-bottom">
            <strong>Продаж автомобіля</strong>
          </h4>
          <p>
            Carcool — це найкраще місце для продажу вашого автомобіля і ми
            зробили цей процес легким.
          </p>
          <strong>1. Надішліть свій автомобіль</strong>
          <p>
            &ensp; <Link to="/SellACar"> Продати свій автомобіль</Link> можна
            безкоштовно . Ми просто просимо вас надати кілька деталей, включаючи
            VIN-код, фотографії та опис автомобіля. Ви також можете вибрати, чи
            хочете ви встановити резервну ціну, чи хочете, щоб ваш автомобіль
            продавався без резерву. Резервна ціна – це мінімальна вартість, за
            яку ви готові продати свій автомобіль. І хоча резервний аукціон може
            здатися привабливим вибором, ми виявили, що транспортні засоби, які
            пропонуються без резерву, отримують більше ставок, більше інтересу
            та більше уваги.
          </p>
          <p>
            Якщо ви виберете резервний аукціон, ми попросимо вас запропонувати
            резервну ціну, але, виходячи з ринкових умов, ми можемо попросити
            нижчу ціну, перш ніж прийняти ваш автомобіль. Майте на увазі, що всі
            наші аукціони починаються від 0 доларів, незалежно від того, чи є у
            них резерв.
          </p>
          <p>
            Наша досвідчена аукціонна команда розгляне вашу заявку та може
            поставити вам додаткові запитання. Ми зробимо все можливе, щоб
            надати вам відповідь протягом робочого дня. Не кожен автомобіль
            підходить для Carcool, але ми завжди цінуємо, що ви знайшли час, щоб
            надати нам свій автомобіль!
          </p>
          <strong>2. Підготуйте своє виставлення</strong>
          <p>
            &ensp; Щоб вашу заявку було прийнято, нам знадобиться від вас деяка
            додаткова інформація, як-от докладні фотографії, історія
            обслуговування та власності тощо. Не хвилюйтеся – ми тут, щоб
            допомогти вам протягом усього процесу!
          </p>
          <ul>
            <li>
              Перш ніж ви зможете продавати на Cacool, ваш автомобіль не має
              бути виставлений на продаж деінде. Це означає, що вам доведеться
              видалити будь-яку іншу рекламу свого автомобіля, і ви не можете
              приймати пропозиції поза межами аукціону.
            </li>
            <li>
              Зробіть чудові фотографії свого автомобіля, щоб отримати більші
              ставки – найміть професійного фотографа або{" "}
              <PdfLink
                URL={"/PDF/photo_guide.pdf"}
                children={"перегляньте наш посібник із фотографій"}
                className={"linkBlack"}
              />
              , перш ніж робити власні фото.
            </li>
            <li>
              Зніміть коротке відео огляд та холодного старту – просто зніміть
              їх на телефон.
            </li>
            <li>
              Відправте свій автомобіль на перевірку, щоб отримати більше грошей
              – ви можете замовити перевірку до того, як вашу заявку буде
              прийнято, і коли ви затвердите свій звіт про перевірку, ми зможемо
              включити його до вашого списку.
            </li>
            <li>
              Прослідкуйте щоб на вашому транспортному засобі не було ніяких
              обтяжень чи обмежень, і від був готовий до переоформлення.
            </li>
            <li>
              Будьте готові до захоплюючого 7-денного аукціону та будьте готові
              взаємодіяти зі спільнотою та відповідати на запитання, щойно вони
              виникнуть.
            </li>
          </ul>
          <strong>3. Завершіть свій аукціон</strong>
          <p>
            &ensp; Після того, як ви надасте нам всю необхідну інформацію та
            фотографії, ми створимо чернетку вашого авто для затвердження. Після
            того, як ви його переглянете та схвалите, ми разом з вами сплануємо
            аукціон, і незабаром після цього він почнеться!
          </p>
          <strong>4. Взяти участь в Аукціоні</strong>
          <ul>
            <li>
              Своєчасна та позитивна участь продавця в аукціоні – відповіді на
              коментарі та запитання, надання додаткових фотографій або відео за
              потреби тощо – має вирішальне значення для успішного аукціону.
              Участь хорошого продавця призведе до додаткового інтересу,
              збільшення ставок і вищої кінцевої ціни продажу.
            </li>
            <li>
              Насолоджуйтесь останніми хвилинами – багато наших аукціонів
              закінчуються захоплюючими війнами ставок, тому будьте готові!
            </li>
          </ul>
          <strong>5. Завершіть свій аукціон</strong>
          <p>
            &ensp; Після завершення аукціону ми надамо вам покроковий
            контрольний список для завершення продажу. Ви також отримаєте
            контактну інформацію покупця (і навпаки), щоб завершити деталі та
            логістику транзакції.{" "}
            <Link to="/" className="linkBlack">
              Дізнайтеся більше про завершення продажу.
            </Link>
          </p>
          <p>
            Якщо на вашому аукціоні був резерв, але він не був виконаний, ми
            зв’яжемося з вами та учасником найвищої ціни, щоб дізнатися, чи
            зможемо ми допомогти укласти угоду!
          </p>
        </div>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <div ref={elementRef3}>
          <h4 className="h4-margin-bottom">
            <strong>Завершення продажу</strong>
          </h4>
          <p>
            &ensp; Коли автомобіль продається на Carcool, ми з’єднуємо покупця
            та продавця, щоб вони могли безпосередньо завершити продаж
            автомобіля, і надаємо їм спеціальний контрольний список, щоб
            забезпечити безперебійну транзакцію. Ось наші рекомендації та поради
            щодо успішного продажу після аукціону – і пам’ятайте, ми тут, щоб
            допомогти!
          </p>
          <strong>1. Встановити контакт</strong>
          <ul>
            <li>
              Зверніться до іншої сторони, щоб представитися, щойно аукціон
              закінчиться, і не забувайте залишатися ввічливими та позитивними,
              щоб процес пройшов гладко.
            </li>
            <li>
              Ми рекомендуємо, щоб продавець надав покупцеві підтвердження права
              власності – як правило, це фотокопія або зображення прав власності
              та реєстрації.
            </li>
          </ul>
          <strong>2. Оплата та документація</strong>
          <ul>
            <li>
              Очікується, що покупці сплатять за автомобіль повністю протягом
              тижня після закриття аукціону.
            </li>
            <li>
              Ми рекомендуємо переоформити автомобіль в сервісному центрі, але
              існує багато можливих варіантів.
            </li>
            <li>Організуйте отримання автомобіля та завершіть угоду.</li>
          </ul>
          <strong>3. Поділіться своєю історією успіху</strong>
          <p>
            &ensp;
            <Link to="/" className="linkBlack">
              Надішліть команді Carcool
            </Link>{" "}
            електронною поштою фотографію передачі, щоб поділитися своєю
            історією успіху – ми будемо раді почути про це!
          </p>
        </div>
      </TextBlock>
    </>
  );
};

export default HowItWorks;
