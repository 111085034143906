import React, { useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Alert,
} from "reactstrap";
import styles from "./ResetPasswordModal.module.css";

const SetPasswordModal = ({ isOpen, toggleModal, handleSetPassword }) => {
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    const newPassword = newPasswordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    if (!newPassword || !confirmPassword) {
      setErrorMessage("Всі поля обов'язкові.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("Новий пароль і підтвердження паролю не збігаються.");
      return;
    }

    setErrorMessage("");
    handleSetPassword(newPassword, setErrorMessage);
  };

  const handleClose = () => {
    setErrorMessage("");
    toggleModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      contentClassName={styles.modalContent}
      centered
    >
      <ModalHeader toggle={handleClose} className={styles.modalHeader}>
        Встановити пароль
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        {errorMessage && (
          <Alert className={styles.alert} color="danger">
            <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </Alert>
        )}
        <FormGroup>
          <Input
            type="password"
            id="newPassword"
            className={styles.inputPassword}
            innerRef={newPasswordRef}
            placeholder="Новий пароль"
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="password"
            id="confirmPassword"
            className={styles.inputPassword}
            innerRef={confirmPasswordRef}
            placeholder="Підтвердити новий пароль"
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className={styles.modalFooter}>
        <Button className={styles.btnCancel} onClick={handleClose}>
          Закрити
        </Button>
        <Button className={styles.btnPrimary} onClick={handleSubmit}>
          Встановити пароль
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SetPasswordModal;
