import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../Hooks/useFetchData";
import DataTable from "../../UI/DataTable";
import TextBlock from "../../UI/TextBlock";
import RequestType from "../../Models/RequestType";
import { useToast } from "../../Contexts/ToastContext";
import ModalWindowAlert from "../../UI/ModalWindowAlert";
import { ReactComponent as IconEye } from "../../../assets/icons/eye-line.svg";
import { ReactComponent as IconEyeOff } from "../../../assets/icons/eye-off-line.svg";
import { ReactComponent as IconDelete } from "../../../assets/icons/delete-bin-line.svg";
import { ReactComponent as IconTable } from "../../../assets/icons/table-line.svg";
import { ReactComponent as IconAdd } from "../../../assets/icons/add-line.svg";
import styles from "./Brands.module.css";
import Loading from "../../UI/Loading";

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [newBrandName, setNewBrandName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedBrandName, setSelectedBrandName] = useState("");
  const { fetchData } = useFetchData("Admin/GetBrands");
  const { fetchData: postData } = useFetchData(
    "Admin/AddNewBrand",
    RequestType.POST
  );
  const { fetchData: deleteData } = useFetchData(
    "Admin/DeleteBrand",
    RequestType.POST
  );
  const { fetchData: hideData } = useFetchData(
    "Admin/HideBrand",
    RequestType.POST
  );
  const { fetchData: showData } = useFetchData(
    "Admin/ShowBrand",
    RequestType.POST
  );
  const { showToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
      .then((data) => {
        setBrands(data);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [fetchData]);

  const handleRowClick = useCallback(
    (id, event) => {
      event.stopPropagation();
      navigate(`/Models/${id}`);
    },
    [navigate]
  );

  const handleHide = useCallback(
    (idBrand) => {
      hideData(idBrand)
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setBrands((prevBrands) =>
              prevBrands.map((brand) =>
                brand.id === idBrand ? { ...brand, isHidden: true } : brand
              )
            );
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to hide brand:", err);
        });
    },
    [hideData, showToast]
  );

  const handleUnhide = useCallback(
    (idBrand) => {
      showData(idBrand)
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setBrands((prevBrands) =>
              prevBrands.map((brand) =>
                brand.id === idBrand ? { ...brand, isHidden: false } : brand
              )
            );
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to unhide brand:", err);
        });
    },
    [showData, showToast]
  );

  const handleDeleteClick = useCallback((idBrand, brandName) => {
    setSelectedBrandId(idBrand);
    setSelectedBrandName(brandName);
    setModalOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    if (selectedBrandId !== null) {
      deleteData(selectedBrandId)
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setBrands((prevBrands) =>
              prevBrands.filter((brand) => brand.id !== selectedBrandId)
            );
            setSelectedBrandId(null);
            setModalOpen(false);
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to delete brand:", err);
        });
    }
  }, [deleteData, selectedBrandId, showToast]);

  const handleAddBrand = useCallback(() => {
    if (newBrandName.trim()) {
      postData({ name: newBrandName.trim() })
        .then((response) => {
          if (response.response) {
            showToast("Успіх", response.message, "success");
            setBrands((prevBrands) => [
              ...prevBrands,
              {
                id: newBrandName.trim(),
                name: newBrandName.trim(),
                isHidden: false,
              },
            ]);
            setNewBrandName("");
          } else {
            showToast("Помилка", response.message, "warning");
          }
        })
        .catch((err) => {
          console.error("Failed to add brand:", err);
        });
    }
  }, [newBrandName, postData, showToast]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: (_, index) => index + 1,
      },
      {
        Header: "Назва",
        accessor: "name",
        Cell: ({ row }) => (
          <div onClick={(event) => handleRowClick(row.original.id, event)}>
            {row.original.isHidden ? (
              <span
                className={`${styles.hiddenBrand} ${styles.spanUrlDecoration}`}
              >
                {row.original.name}
              </span>
            ) : (
              <span className={styles.spanUrlDecoration}>
                {row.original.name}
              </span>
            )}
          </div>
        ),
      },
      {
        Header: "Відображення",
        accessor: "visibility",
        disableSort: true,
        Cell: ({ row }) => (
          <div className={styles.actionButton}>
            {row.original.isHidden ? (
              <Button
                color="success"
                onClick={() => handleUnhide(row.original.id)}
              >
                <IconEye className={styles.icon} />
                Відобразити
              </Button>
            ) : (
              <Button
                color="secondary"
                onClick={() => handleHide(row.original.id)}
              >
                <IconEyeOff className={styles.icon} />
                Приховати
              </Button>
            )}
          </div>
        ),
      },
      {
        Header: "Видалення",
        accessor: "deletion",
        disableSort: true,
        Cell: ({ row }) => (
          <div className={styles.actionButton}>
            <Button
              color="danger"
              onClick={() =>
                handleDeleteClick(row.original.id, row.original.name)
              }
            >
              <IconDelete className={styles.icon} />
              Видалити
            </Button>
          </div>
        ),
      },
      {
        Header: "Перехід",
        accessor: "navigation",
        disableSort: true,
        Cell: ({ row }) => (
          <div className={styles.actionButton}>
            <Button
              onClick={(event) => handleRowClick(row.original.id, event)}
              className={styles.btnInfo}
            >
              <IconTable className={styles.icon} />
              Перейти до моделей
            </Button>
          </div>
        ),
      },
    ],
    [handleHide, handleRowClick, handleUnhide, handleDeleteClick]
  );

  if (!brands || Object.keys(brands).length === 0) {
    return <Loading />;
  }

  return (
    <TextBlock color="#fff" className="cardText">
      <h2>
        <strong>Бренди</strong>
      </h2>
      <div className={styles.addContainer}>
        <Input
          value={newBrandName}
          onChange={(e) => setNewBrandName(e.target.value)}
          placeholder="Введіть назву бренду"
          style={{ marginBottom: "10px" }}
        />
        <Button className={styles.btnAdd} onClick={handleAddBrand}>
          <IconAdd className={styles.icon} />
          Додати бренд
        </Button>
      </div>

      <ModalWindowAlert
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        text={`Ви впевнені, що хочете видалити бренд "${selectedBrandName}"?`}
        handleDeleteConfirm={handleDeleteConfirm}
      />

      <DataTable data={brands} columns={columns} />
    </TextBlock>
  );
};

export default Brands;
