import React, { useState, useEffect, useCallback } from "react";
import CardList from "../HomeComponents/CardList";
import Filters from "../HomeComponents/Filters";
import useFetchData from "../Hooks/useFetchData";
import { useAuth } from "../Contexts/AuthContext";
import { Form, InputGroup, Input } from "reactstrap";
import styles from "./Home.module.css";
import DefaultSelect from "../UI/DefaultSelect";

const transformObject = (data) => {
  if (!data || typeof data !== "object") {
    return [];
  }
  return Object.entries(data).map(([key, value]) => ({
    value: key,
    label: value,
  }));
};

const Home = () => {
  const initialFilters = {
    selectedBrands: [],
    selectedModels: [],
    selectedCarTypes: [],
    selectedTransmissionTypes: [],
    selectedFuelTypes: [],
    selectedDriveTypes: [],
    selectedYearFrom: null,
    selectedYearTo: null,
    mileageFrom: "",
    mileageTo: "",
    priceFrom: "",
    priceTo: "",
    selectedRegions: [],
  };
  const [cars, setCars] = useState([]);
  const [sort, setSort] = useState([]);

  const [selectedOption, setSelectedOption] = useState({
    value: "SortLotCard_0",
    label: "Скоро закінчується",
  });
  const [filterWidth, setFilterWidth] = useState("25%");
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCards, setFilteredCards] = useState([]);
  const [isCarsLoaded, setIsCarsLoaded] = useState(false);

  const [noReserveFilterActive, setNoReserveFilterActive] = useState(false);

  const { isAuthenticated } = useAuth();

  const { fetchData: fetchDataCars } = useFetchData(
    "Auction/GetListLotViewModel"
  );

  const { fetchData: fetchSortingData } = useFetchData(
    "Filters/GetSortingData"
  );

  const { fetchData: fetchFavoritesData } = useFetchData(
    isAuthenticated() ? "Auction/GetFavorites" : null
  );

  useEffect(() => {
    fetchDataCars()
      .then((data) => {
        setCars(data);
        setFilteredCards(data);
        setIsCarsLoaded(true);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [fetchDataCars]);

  useEffect(() => {
    fetchSortingData()
      .then((data) => {
        const transformSort = transformObject(data);
        setSort(transformSort);
      })
      .catch((err) => {
        console.error("Failed to fetch data:", err);
      });
  }, [fetchSortingData]);

  useEffect(() => {
    if (isAuthenticated() && isCarsLoaded) {
      fetchFavoritesData()
        .then((data) => {
          const favoriteIds = data.map((item) => item.lotId);
          setCars((prevCars) => {
            const updatedCars = prevCars.map((car) => {
              if (favoriteIds.includes(car.lotID)) {
                return { ...car, favorite: true };
              }
              return car;
            });
            setFilteredCards(updatedCars);
            return updatedCars;
          });
          setIsCarsLoaded(false);
        })
        .catch((err) => {
          console.error("Failed to fetch data:", err);
        });
    }
  }, [cars, isCarsLoaded, fetchFavoritesData, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated()) {
      setCars((prevCars) => {
        const updatedCars = prevCars.map((car) => {
          return { ...car, favorite: false };
        });
        setFilteredCards(updatedCars);
        return updatedCars;
      });
    }
  }, [isAuthenticated]);

  const toggleFavorite = useCallback((id) => {
    setFilteredCards((prevFilteredCards) => {
      const updatedCards = prevFilteredCards.map((card) => {
        if (card.lotID === id) {
          return { ...card, favorite: !card.favorite };
        }
        return card;
      });
      return updatedCards;
    });
  }, []);

  const filterBySearchTerm = useCallback((cars, term) => {
    if (!Array.isArray(cars)) {
      return [];
    }

    const lowerTerm = term.toLowerCase();

    return cars.filter((car) => {
      const brand = car.brand?.key?.toLowerCase() || "";
      const model = car.model?.key?.toLowerCase() || "";
      const productionYear = car.productionYear?.toString() || "";

      return (
        brand.includes(lowerTerm) ||
        model.includes(lowerTerm) ||
        productionYear.includes(lowerTerm)
      );
    });
  }, []);

  const sortFilteredAndFilteredCards = useCallback(
    (option, filters) => {
      let sortedFilteredCars = [...cars];
      if (option && option.value !== undefined) {
        switch (option.value) {
          case "SortLotCard_0":
            sortedFilteredCars = sortedFilteredCars.sort(
              (a, b) => new Date(a.timerEndTime) - new Date(b.timerEndTime)
            );
            break;
          case "SortLotCard_1":
            sortedFilteredCars = sortedFilteredCars.sort(
              (a, b) => new Date(b.timerEndTime) - new Date(a.timerEndTime)
            );
            break;
          case "SortLotCard_2":
            sortedFilteredCars = sortedFilteredCars.sort(
              (a, b) => a.mileage - b.mileage
            );
            break;
          case "SortLotCard_3":
            sortedFilteredCars = sortedFilteredCars.sort(
              (a, b) => new Date(b.productionYear) - new Date(a.productionYear)
            );
            break;
          default:
            break;
        }
      } else {
        sortedFilteredCars = sortedFilteredCars.sort(
          (a, b) => new Date(a.timerEndTime) - new Date(b.timerEndTime)
        );
      }

      // Фільтрація за власними критеріями
      const filteredCars = sortedFilteredCars.filter((car) => {
        const {
          brand,
          model,
          body,
          gearbox,
          fuelType,
          drive,
          mileage,
          price,
          region,
          productionYear,
          hasReservePrice,
        } = car;
        const {
          selectedBrands,
          selectedModels,
          selectedCarTypes,
          selectedTransmissionTypes,
          selectedFuelTypes,
          selectedDriveTypes,
          selectedYearFrom,
          selectedYearTo,
          mileageFrom,
          mileageTo,
          priceFrom,
          priceTo,
          selectedRegions,
        } = filters;
        const brandSelected =
          selectedBrands.length === 0 ||
          selectedBrands.includes(brand.key.toUpperCase());
        let modelSelected = true;
        if (selectedModels && Object.keys(selectedModels).length > 0) {
          modelSelected =
            !selectedModels[brand.key.toUpperCase()] ||
            selectedModels[brand.key.toUpperCase()].length === 0 ||
            selectedModels[brand.key.toUpperCase()].includes(
              model.key.toUpperCase()
            );
        }
        const withoutReserveCondition = noReserveFilterActive
          ? !hasReservePrice
          : true;
        return (
          brandSelected &&
          modelSelected &&
          (selectedCarTypes.length === 0 ||
            selectedCarTypes.includes(body.key)) &&
          (selectedTransmissionTypes.length === 0 ||
            selectedTransmissionTypes.includes(gearbox.key || "")) &&
          (selectedFuelTypes.length === 0 ||
            selectedFuelTypes.includes(fuelType.key || "")) &&
          (selectedDriveTypes.length === 0 ||
            selectedDriveTypes.includes(drive.key || "")) &&
          (selectedYearFrom === null ||
            selectedYearFrom.value <= productionYear) &&
          (selectedYearTo === null || selectedYearTo.value >= productionYear) &&
          (mileageFrom === "" || parseInt(mileageFrom * 1000) <= mileage) &&
          (mileageTo === "" || parseInt(mileageTo * 1000) >= mileage) &&
          (priceFrom === "" || parseInt(priceFrom) <= price) &&
          (priceTo === "" || parseInt(priceTo) >= price) &&
          (selectedRegions.length === 0 ||
            selectedRegions.includes(region.key)) &&
          withoutReserveCondition
        );
      });

      setFilteredCards(filteredCars);
      return filteredCars;
    },
    [noReserveFilterActive, cars]
  );

  const applyFilters = useCallback(
    (filters) => {
      let filteredCars = sortFilteredAndFilteredCards(selectedOption, filters);
      if (searchTerm) {
        filteredCars = filterBySearchTerm(filteredCars, searchTerm);
      }
      setFilteredCards(filteredCars || []);
    },
    [
      selectedOption,
      sortFilteredAndFilteredCards,
      searchTerm,
      filterBySearchTerm,
    ]
  );

  const filterAndSortCars = useCallback(() => {
    let filteredCars = sortFilteredAndFilteredCards(
      selectedOption,
      selectedFilters
    );
    if (searchTerm) {
      filteredCars = filterBySearchTerm(filteredCars, searchTerm);
    }

    setFilteredCards(filteredCars);
  }, [
    selectedOption,
    selectedFilters,
    searchTerm,
    sortFilteredAndFilteredCards,
    filterBySearchTerm,
  ]);

  useEffect(() => {
    filterAndSortCars();
  }, [selectedOption, selectedFilters, searchTerm, cars, filterAndSortCars]);

  const handleFilterResize = (newWidth) => {
    setFilterWidth(newWidth);
  };

  const handleResetFilters = () => {
    setSelectedFilters(initialFilters);
    setNoReserveFilterActive(false);
    setSelectedOption(sort[0]);
  };

  const handleNoReserveClick = () => {
    setNoReserveFilterActive((prevState) => !prevState);
    if (!noReserveFilterActive) {
      const updatedFilters = {
        ...selectedFilters,
        hasReservePrice: true,
      };
      applyFilters(updatedFilters);
    } else {
      const { hasReservePrice, ...rest } = selectedFilters;
      applyFilters(rest);
    }
  };

  const handleSelectedOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const card =
    filterWidth === "25%" ? "col-xxl-10 col-xl-12" : "col-xxl-11 col-xl-12";

  return (
    <div
      className="row justify-content-between"
      style={{ marginLeft: filterWidth === "25%" ? "0" : "-2%" }}
    >
      <div
        className={`d-flex justify-content-between align-items-center ${styles.topRowSort}`}
      >
        <div className="d-flex align-items-center">
          <p
            className="text-center"
            style={{ marginBottom: 0, marginRight: "20px" }}
          >
            Знайдено {filteredCards ? filteredCards.length : 0} автомобілей
          </p>
          {Object.values(selectedFilters).some((filter) =>
            Array.isArray(filter) ? filter.length > 0 : filter
          ) && (
            <button onClick={handleResetFilters} className={styles.btnRemove}>
              Скинути фільтри
            </button>
          )}
        </div>
        <div
          className={`d-flex justify-content-end align-items-center ${styles.contentSort}`}
        >
          <Form className={`d-flex ${styles.searchForm}`}>
            <InputGroup className={styles.inputGroupMainSearch}>
              <Input
                id="exampleSearch"
                name="search"
                placeholder="Пошук авто"
                type="search"
                className={styles.searchInput}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </Form>
          <div className={styles.sortElement}>
            <div className={styles.selectSortSize}>
              <DefaultSelect
                options={sort}
                value={selectedOption}
                onChange={handleSelectedOptionChange}
              />
            </div>
            <button
              className={styles.buttonSort}
              onClick={handleNoReserveClick}
            >
              {noReserveFilterActive ? "Всі автомобілі" : "Без резерву"}
            </button>
          </div>
        </div>
      </div>
      <div
        className={`col-xxl-2 col-xl-12 ${styles.mobaileObject}`}
        style={{
          width: filterWidth,
          paddingRight: "20px",
          paddingLeft: "0px",
        }}
      >
        <Filters
          onFilterResize={handleFilterResize}
          applyFilters={applyFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </div>
      <div
        className={`${card} ${styles.mobaileObject}`}
        style={{ padding: "0", width: filterWidth === "25%" ? "75%" : "93.1%" }}
      >
        <CardList
          filteredCards={filteredCards}
          toggleFavorite={toggleFavorite}
        />
      </div>
    </div>
  );
};

export default Home;
