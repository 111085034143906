import React from "react";
import TextBlock from "./TextBlock";
import styles from "./Identification.module.css";
import { NavLink } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import GoogleButton from "./GoogleButton";
import AuthCarousel from "./AuthCarousel";
import { useGoogleLogin } from "@react-oauth/google";
import { useAuth } from "../Contexts/AuthContext";

const Identification = ({
  header,
  contextText,
  linkText,
  clickEvent,
  children,
  hideGoogleButton,
}) => {
  const { loginWithGoogle } = useAuth();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const result = await loginWithGoogle(tokenResponse.code);
        if (result.success) {
          navigate("/");
        } else {
          console.error("Google login failed:", result.message);
        }
      } catch (error) {
        console.error("Google login error:", error);
      }
    },
    onError: () => {
      console.log("Login Failed");
    },
    scope: "openid email profile",
    auto_select: true,
    flow: "auth-code",
  });

  return (
    <div className={`row mx-0 ${styles.identification}`}>
      <div className="col-xxl-7 col-xl-7 col-lg-12">
        <div className="row justify-content-center align-items-center h-100">
          <div className={`${styles.formContainer} col-xxl-6 p-5`}>
            <div className={`${styles.LogoContainer} mb-1`}>
              <NavLink tag={Link} to="/" className={styles.navLink}>
                <img
                  className={styles.Logo}
                  src="/images/CARCOOL.png"
                  alt="Logo"
                />
              </NavLink>
            </div>
            <TextBlock className={`${styles.Header} mb-3`}>{header}</TextBlock>
            <TextBlock className={`${styles.ContextText} mb-3`}>
              {contextText}{" "}
              <span className={styles.Span} onClick={clickEvent}>
                {linkText}
              </span>
            </TextBlock>
            {!hideGoogleButton && (
              <>
                <TextBlock className="mb-3">
                  <GoogleButton
                    handleGoogleLoginClick={login}
                    className={styles.googleButton}
                    text={"Вхід з акаунтом Google"}
                  />
                </TextBlock>
                <div>
                  <div className={`${styles.line} mt-1 mb-1`}>або</div>
                </div>
              </>
            )}
            <div className={`${styles.ModalBody} mt-3`}>{children}</div>
          </div>
        </div>
      </div>
      <div className="col-xxl-5 col-xl-5 col-lg-5 d-xl-block d-none px-0">
        <div className={styles.authentication}>
          <div className={`${styles.aunthenticationContent} ${styles.rounded}`}>
            <AuthCarousel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Identification;
