import React, { useEffect } from "react";
import TextBlock from "../UI/TextBlock";
import { Link } from "react-router-dom";

const colorBlue = "#dbf6fa";
const colorWhite = "#fff";

const UseOfTerms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="row col-xxl-9 col-xl-12" style={{ margin: "auto" }}>
      <TextBlock color={colorWhite} className="cardText">
        <h2 className="h4-margin-bottom d-flex justify-content-center">
          <strong>Умови використання Carcool.ua</strong>
        </h2>
        <p style={{ textIndent: "30px" }}>
          Ласкаво просимо до Carcool! Будь ласка, прочитайте далі, щоб дізнатися
          про правила та обмеження, які регулюють використання вами нашого
          веб-сайту(ів), продуктів, послуг і програм (далі «Платформа»). Якщо у
          вас виникли запитання, коментарі чи сумніви щодо цих умов або
          Платформи, зв’яжіться з нами за адресою:
          <br />
          <strong>Електронна адреса:</strong> carcool365@gmail.com
          <br />
          <strong>Телефон:</strong>
        </p>
        <p style={{ textIndent: "30px" }}>
          Данні умови є публічним договором приєднання. Будь-яке відвідування
          Користувачем Платформи свідчить про приєднання Користувача до цих
          умов. Данні умови в рівній мірі поширюються як на зареєстрованих, так
          і на не зареєстрованих Користувачів. Будь-яке використання Платформи
          Користувачем повністю підтверджує свою згоду з перерахованими нижче
          умовами. Якщо Користувач не погоджується з будь-яким пунктом цих умов,
          він має негайно припинити свій візит та будь-яке використання
          Платформи.
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>Зв’язок між продавцями та учасниками торгів</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Carcool об’єднує тих, хто пропонує автомобілі на продаж (далі
          «Продавці»), і тих, хто хоче придбати автомобілі (далі «Учасники
          торгів»). Платформа діє лише як місце зустрічі; фактичний договір
          продажу кожного автомобіля, зазначеного на Платформі, укладається
          безпосередньо між Продавцем і переможцем торгів.
        </p>
        <p style={{ textIndent: "30px" }}>
          Коли ми використовуємо слово «ви» в цих Умовах, воно стосується
          будь-якого користувача, незалежно від того, чи є він або вона
          Продавцем, Учасником торгів, іншим зареєстрованим користувачем чи
          незареєстрованим користувачем, тоді як якщо ми використовуємо один із
          цих конкретних термінів, це стосується лише для цієї категорії
          користувачів.
        </p>
        <p style={{ textIndent: "30px" }}>
          Перш ніж придбати автомобіль у будь-якого Продавця (кожен –
          «Автомобіль Продавця»), Учасники торгів несуть відповідальність за
          власне визначення того, чи підходить Автомобіль Продавця. Carcool несе
          відповідальність лише за зв’язок Продавців і Покупців і не може і не
          буде відповідати за те, щоб будь-який Автомобіль Продавця відповідав
          певному стандарту якості. Carcool так само не може і не несе
          відповідальності за те, щоб інформація (включно з обліковими даними),
          яку будь-який Учасник торгів або Продавець надає про себе або про
          будь-який автомобіль Продавця, який він або вона пропонує, була точною
          чи актуальною. Кожен Продавець визнає та погоджується, що він докладе
          розумних зусиль для завершення продажу свого Автомобіля Продавця, якщо
          виграє ставка. Кожен Учасник торгів визнає та погоджується з тим, що
          якщо він є переможцем аукціону через платформу, його ставка є
          обов’язковою для Учасника торгів, і Учасник торгів несе
          відповідальність за всі відповідні державні збори та податки за
          транспортний засіб, виграний за допомогою такої пропозиції. Незважаючи
          на вищесказане, ми не контролюємо дії жодного Учасника торгів або
          Продавця, а Продавці не є нашими співробітниками.
        </p>
        <p style={{ textIndent: "30px" }}>
          Carcool не пропонує безпосередньо Продавцю автомобілі. Цим ви
          визнаєте, що Carcool не наглядає, не спрямовує, не контролює та не
          контролює Автомобілі Продавця, і прямо відмовляється від будь-якої
          відповідальності за Автомобілі Продавця, включаючи, але не обмежуючись
          будь-якими гарантіями чи умовами якості чи придатності для певної
          мети, або відповідність будь-якому закону, постанові чи кодексу.
        </p>
        <p style={{ textIndent: "30px" }}>
          Продавці не можуть видалити автомобіль, виставлений на аукціон на
          Платформі, до завершення такого аукціону. Поки Автомобіль Продавця
          розміщено на Платформі, Продавець не може розміщувати або надавати
          такий Автомобіль Продавця на будь-якому іншому аукціоні, у дилерському
          центрі, службі розміщення чи публікації.
        </p>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>Чи змінюються ці Умови?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Ми постійно намагаємося покращити нашу Платформу, тому ці Умови,
          можливо, доведеться змінити разом із нашою Платформою. Ми залишаємо за
          собою право будь-коли змінити Умови, але якщо ми це зробимо, ми
          розмістимо сповіщення на нашому сайті, надішлемо вам електронний лист
          та/або сповістимо вас іншим способом.
        </p>
        <p style={{ textIndent: "30px" }}>
          Якщо ви не згодні з новими Умовами, ви можете відхилити їх; на жаль,
          це означає, що ви більше не зможете користуватися Платформою. Якщо ви
          будь-яким чином використовуєте Платформу після того, як зміни в Умовах
          набудуть чинності, це означає, що ви погоджуєтеся з усіма змінами.
        </p>
        <p style={{ textIndent: "30px" }}>
          За винятком змін, внесених нами, як описано тут, жодні інші поправки
          чи модифікації цих Умов не будуть дійсними, якщо вони не будуть
          оформлені в письмовій формі та підписані вами та нами.
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom d-flex justify-content-center">
          <strong>Щодо приватності</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Компанія вживає належних заходів до захисту персональних даних
          користувачів. З інформацією про захист персональних даних Ви можете
          ознайомитись в{" "}
          <Link to={"/PrivacyPolicy"} className="linkPrimary">
            політиках конфіденційності.
          </Link>
        </p>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom">
          <strong>Які основи використання Carcool?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Вам може знадобитися зареєструвати обліковий запис, вибрати пароль і
          ім’я користувача («Логін користувача Carcool») і надати нам певну
          інформацію чи дані, наприклад вашу контактну інформацію. Ви обіцяєте
          надати нам точну, повну й оновлену реєстраційну інформацію про себе.
          Ви не можете вибрати як ідентифікатор користувача Carcool ім’я, яке ви
          не маєте права використовувати, або ім’я іншої особи з наміром видати
          себе за цю особу. Ви не можете передавати свій обліковий запис нікому
          без нашого попереднього письмового дозволу.
        </p>
        <p style={{ textIndent: "30px" }}>
          Ви заявляєте та гарантуєте, що ви досягли повноліття для укладення
          обов’язкового договору (або якщо ні, ви отримали дозвіл своїх батьків
          або опікуна на використання Платформи і переконалися, що ваші батьки
          або опікун погоджуються з цими Умовами від вашого імені ). Якщо ви
          погоджуєтеся з цими Умовами від імені організації чи організації, ви
          заявляєте та гарантуєте, що ви маєте право погоджуватися з цими
          Умовами від імені цієї організації чи організації та зв’язувати їх із
          цими Умовами.
        </p>
        <p style={{ textIndent: "30px" }}>
          Ви використовуватимете Платформу лише для власного внутрішнього
          особистого використання, а не від імені чи на користь будь-якої
          третьої сторони. Ви будете дотримуватися всіх законів, які стосуються
          вас, використання вами Платформи, а також ваших дій і бездіяльності,
          пов’язаних із Платформою (наприклад, Продавці повинні дотримуватися
          всіх законів, які стосуються Автомобілів Продавця). Якщо використання
          вами Платформи заборонено чинним законодавством, ви не маєте права
          користуватися Платформою. Ми не можемо і не будемо нести
          відповідальність за використання вами Платформи у спосіб, який порушує
          закон.
        </p>
        <p style={{ textIndent: "30px" }}>
          Ви нікому не повідомлятимете свій логін користувача Carcool, обліковий
          запис або пароль, і ви повинні захищати безпеку свого логіна
          користувача Carcool, облікового запису, пароля та будь-яких інших
          інструментів доступу чи облікових даних. Ви несете відповідальність за
          будь-які дії, пов’язані з вашим логіном користувача та обліковим
          записом Carcool.
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>А як щодо обміну повідомленнями?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          У рамках Платформи ви можете отримувати повідомлення через Платформу,
          включно з повідомленнями, які Carcool надсилає вам (наприклад,
          електронною поштою). Під час реєстрації на Платформі ви отримаєте
          вітальне повідомлення та інструкції щодо того, як припинити отримання
          певних маркетингових повідомлень.
        </p>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>Чи є обмеження щодо використання Платформи?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Ви заявляєте, гарантуєте та погоджуєтеся, що не будете вносити
          будь-який Вміст або Користувацькі матеріали (кожен із цих термінів
          визначено нижче) чи іншим чином використовувати Платформу чи
          взаємодіяти з Платформою таким чином, щоб:
        </p>
        <ol className="mt-3 mb-3">
          <li className="mb-3">
            порушує права інтелектуальної власності чи будь-які інші права
            будь-кого іншого (включаючи Carcool);
          </li>
          <li className="mb-3">
            порушує будь-які закони чи нормативно правові акти, включаючи, але
            не обмежуючись , закони про конфіденційність, або будь які правила
            Carcool;
          </li>
          <li className="mb-3">
            є небезпечним, шкідливим, шахрайським, оманливим, погрозливим,
            агресивним, наклепницьким, непристойним або іншим чином неприйнятним
            (зокрема, шляхом створення кількох облікових записів з метою обману
            або обману системи ставок);
          </li>
          <li className="mb-3">
            ставить під загрозу безпеку ваш ідентифікатор користувача Carcool,
            облікового запису чи будь-кого іншого (наприклад, дозволяє комусь
            іншому входити на Платформу від вашого імені);
          </li>
          <li className="mb-3">
            спроби будь-яким способом отримати пароль, обліковий запис або іншу
            інформацію безпеки від будь-якого іншого користувача;
          </li>
          <li className="mb-3">
            порушує безпеку будь-якої комп’ютерної мережі або зламує будь-які
            паролі чи коди шифрування безпеки;
          </li>
          <li className="mb-3">
            запускає будь-яку форму автовідповідача чи «спаму» на Платформі або
            будь-які процеси, які запускаються чи активуються, поки ви не
            ввійшли на Платформу, або іншим чином заважають належній роботі
            Платформи. (в тому числі шляхом невиправданого навантаження на
            інфраструктуру Сервісів);
          </li>
          <li className="mb-3">
            копіює або зберігає будь-яку частину інформації;
          </li>
          <li className="mb-3">
            декомпілює, виконує зворотне проектування або іншим чином
            намагається отримати вихідний код або базові ідеї чи інформацію,
            пов’язану з Платформою.
          </li>
        </ol>
        <p style={{ textIndent: "30px" }}>
          Порушення будь-якого з вищезазначених є підставою для припинення
          вашого права на використання або доступ до Платформи.
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>
            Як я можу використовувати інформацію, отриману від інших
            користувачів?
          </strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Ви не маєте права збирати будь-яку інформацію від іншого користувача
          або пов’язану з ним («Інформація користувача») за допомогою Платформи,
          під час пропонування або надання пропозиції щодо Автомобілів Продавця,
          окрім того, що необхідно для завершення вашої угоди і проведення
          аукціонної операції. Після завершення аукціону ви повинні належним
          чином знищити всю інформацію користувача від такого користувача або
          пов’язану з ним і більше не використовувати її. Ви повинні збирати,
          використовувати, підтримувати та передавати всю інформацію користувача
          відповідно до всіх законів України.
        </p>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>Які мої права на Платформі?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Матеріали, які відображаються або виконуються або доступні на
          Платформі або через них, включаючи, але не обмежуючись цим, текст,
          графіку, дані, статті, фотографії, зображення, ілюстрації,
          Користувацькі матеріали (як визначено нижче) тощо (все вищезазначене,
          «Вміст») захищені авторським правом та/або іншими законами про
          інтелектуальну власність. Ви обіцяєте дотримуватися всіх повідомлень
          про авторські права, правил торгових марок, інформації та обмежень, що
          містяться в будь-якому Вмісті, до якого ви отримуєте доступ через
          Платформу, і не будете використовувати, копіювати, відтворювати,
          змінювати, перекладати, публікувати, транслювати, передавати,
          поширювати, виконувати , завантажувати, відображати, ліцензувати,
          продавати, комерціалізувати чи іншим чином використовувати з будь-якою
          метою будь-який Вміст, який не належить вам, без попередньої згоди
          власника цього Вмісту або у спосіб, який порушує чиїсь права
          (включаючи Carcool).
        </p>
        <p style={{ textIndent: "30px" }}>
          Згідно з цими Умовами ми надаємо кожному користувачеві Платформи
          ліцензію, яка не підлягає субліцензії та передачі, на використання
          (тобто завантаження та відображення локально) Вмісту виключно з метою
          використання Платформи. Використання, відтворення, модифікація,
          розповсюдження або зберігання будь-якого Вмісту для будь-яких цілей,
          крім використання Платформи, прямо заборонено без попереднього
          письмового дозволу від нас. Ви розумієте, що Carcool володіє
          Платформою. Ви не будете змінювати, публікувати, передавати, брати
          участь у передачі чи продажу, відтворювати (крім випадків, прямо
          зазначених у цьому Розділі), створювати похідні роботи на основі або
          іншим чином використовувати Платформу. Платформа може дозволяти вам
          копіювати або завантажувати певний Вміст, але пам’ятайте, що навіть
          якщо ці функції існують, усі обмеження в цьому розділі все ще
          застосовуються.
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>
            Як щодо всього, що я вношу на Платформу – чи маю я надавати будь-які
            ліцензії Carcool чи іншим користувачам?
          </strong>
        </h4>
        <h4 className="h4-margin-bottom d-flex justify-content-center">
          <strong>Матеріали користувача</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Усе, що ви публікуєте, завантажуєте, ділитеся, зберігаєте чи іншим
          чином надаєте через Платформу, є вашим «Користувацьким матеріалом».
          Деякі матеріали користувача можуть переглядати інші користувачі. Ви
          несете повну відповідальність за всі Користувацькі матеріали, які ви
          додаєте до Платформи. Ви заявляєте, що всі надані вами Користувацькі
          матеріали є точними, повними, актуальними та відповідають усім чинним
          законам, правилам і нормам.
        </p>
        <p style={{ textIndent: "30px" }}>
          {" "}
          Ви погоджуєтеся з тим, що не будете публікувати, завантажувати,
          надавати спільний доступ, зберігати або іншим чином надавати за
          допомогою Платформи будь-які Користувацькі матеріали, які:
        </p>
        <ol className="mb-3">
          <li className="mb-3">
            порушують авторські права чи інші права третіх сторін (наприклад,
            права на торговельну марку, права на конфіденційність тощо);
          </li>
          <li className="mb-3">
            містити відверто сексуальний вміст або порнографію;
          </li>
          <li className="mb-3">
            містити ненависницький, наклепницький або дискримінаційний вміст або
            розпалювати ненависть до будь-якої особи чи групи;
          </li>
          <li className="mb-3">експлуатувати неповнолітніх;</li>
          <li className="mb-3">
            зображувати протизаконні дії чи крайнє насильство;
          </li>
          <li className="mb-3">
            зображення жорстокого поводження з тваринами або надзвичайного
            насильства по відношенню до них;
          </li>
          <li className="mb-3">
            просування шахрайських схем, схем багаторівневого маркетингу (MLM),
            схем швидкого збагачення, онлайн-ігор та азартних ігор, грошових
            подарунків, роботи на дому чи будь-яких інших сумнівних підприємств
            із заробітком грошей;
          </li>
          <li className="mb-3">порушувати будь-який закон.</li>
        </ol>
        <h4 className="h4-margin-bottom d-flex justify-content-center">
          <strong>Ліцензії</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Щоб відображати ваші Користувацькі матеріали на Платформі і дозволити
          іншим користувачам користуватися ними (де це можливо), ви надаєте нам
          певні права на ці Користувацькі матеріали (додаткову інформацію див.
          нижче). Зауважте, що всі наведені нижче ліцензії підпадають під дію
          нашої Політики конфіденційності в тій мірі, в якій вони стосуються
          Надісланих користувачами матеріалів, які також є вашою особистою
          інформацією.
        </p>
        <p style={{ textIndent: "30px" }}>
          Надсилаючи Користувацькі матеріали через Платформу, ви цим надаєте та
          надаєте Carcool невиключну, постійну, безоплатну, повністю оплачену,
          субліцензійну та передану ліцензію на використання, редагування,
          зміну, скорочення, агрегування, відтворення, розповсюджувати, готувати
          похідні роботи, відображати, виконувати та іншим чином повністю
          використовувати Користувацькі матеріали у зв’язку з цим сайтом,
          Платформою та нашим бізнесом (і наших правонаступників і
          правонаступників), включаючи, без обмежень, для просування та
          повторного розповсюдження частини або всього цей сайт або Послуги (і
          їх похідні роботи) у будь-яких медіа-форматах і через будь-які
          медіа-канали (включаючи, без обмежень, сторонні веб-сайти та канали),
          і в тому числі після припинення вашого облікового запису або
          Платформи. Цим ви також надаєте та надаєте кожному користувачеві цього
          сайту та/або Платформи невиключну безстрокову ліцензію на доступ до
          ваших Користувацьких матеріалів через цей сайт та/або Платформу, а
          також на використання, редагування, змінення, відтворення,
          розповсюдження, готувати похідні роботи, відображати та виконувати
          такі Користувацькі матеріали, у тому числі після припинення вашого
          облікового запису або Платформи. Для ясності, зазначена вище ліцензія,
          яка надається нам і нашим користувачам, не впливає на інше ваше право
          власності або ліцензійні права на ваші Користувацькі матеріали,
          включаючи право надавати додаткові ліцензії на ваші Користувацькі
          матеріали, якщо інше не погоджено в письмовій формі. Ви заявляєте та
          гарантуєте, що маєте всі права надавати нам такі ліцензії без
          порушення будь-яких прав третіх осіб, включаючи, без обмежень,
          будь-які права на конфіденційність, права на публічність, авторські
          права, права на торговельні марки, контрактні права або будь-яку іншу
          інтелектуальну чи майнову власність прав.
        </p>
        <p style={{ textIndent: "30px" }}>
          Нарешті, ви розумієте та погоджуєтеся, що компанії Carcool під час
          виконання необхідних технічних кроків для надання Платформи нашим
          користувачам (включно з вами) може знадобитися внести зміни у ваші
          Користувацькі матеріали, щоб відповідати та адаптувати ці
          Користувацькі матеріали до технічних вимог мереж підключення,
          пристроїв, служб або засобів масової інформації, а зазначені вище
          ліцензії включають права на це.
        </p>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>
            Хто відповідає за те, що я бачу та роблю на Платформі?
          </strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Відповідальність за будь-яку інформацію чи Контент, публічно
          опублікований або приватно переданий через Платформу, несе особа, від
          якої такий Контент походить, і ви отримуєте доступ до всієї такої
          інформації та Контенту на свій власний ризик, і ми не несемо
          відповідальності за будь-які помилки чи упущення в цю інформацію чи
          Вміст або за будь-які збитки чи втрати, які ви можете зазнати у
          зв’язку з цим. Ми не можемо контролювати та не маємо обов’язку вживати
          будь-яких дій щодо того, як ви можете інтерпретувати та
          використовувати Вміст або які дії ви можете вживати в результаті
          контакту з Вмістом, і цим ви звільняєте нас від будь-якої
          відповідальності за те, що ви придбали або Вміст, отриманий не через
          Платформу. Ми не можемо гарантувати ідентифікацію будь-яких
          користувачів, з якими ви взаємодієте під час використання Платформи, і
          не несемо відповідальності за те, які користувачі отримують доступ до
          Платформи.
        </p>
        <p style={{ textIndent: "30px" }}>
          Ви несете відповідальність за весь Вміст, який ви будь-яким чином
          додаєте до Платформи, і ви заявляєте та гарантуєте, що маєте всі
          права, необхідні для цього, у спосіб, у який ви його додаєте.
        </p>
        <p style={{ textIndent: "30px" }}>
          Платформа може містити посилання або підключення до сторонніх
          веб-сайтів або служб, які не належать або не контролюються Carcool.
          Коли ви заходите на веб-сайти третіх сторін або користуєтеся
          сторонніми службами, ви погоджуєтеся з тим, що це пов’язано з
          ризиками, і Carcool не несе відповідальності за такі ризики.
        </p>
        <p style={{ textIndent: "30px" }}>
          Компанія Carcool не контролює та не несе відповідальності за зміст,
          точність, політику конфіденційності або думки, висловлені на
          веб-сайтах третіх сторін або будь-якою третьою стороною, з якою ви
          взаємодієте через Платформу. Крім того, Carcool не буде і не може
          контролювати, перевіряти, цензурувати або редагувати вміст будь-якого
          стороннього сайту чи служби. Ми рекомендуємо вам бути в курсі, коли ви
          залишаєте Платформі, і ознайомитися з положеннями та умовами та
          політикою конфіденційності кожного веб-сайту або служби третьої
          сторони, які ви відвідуєте або використовуєте. Використовуючи
          Платформу, ви звільняєте нас від будь-якої відповідальності, що
          виникає внаслідок використання вами будь-якого стороннього веб-сайту
          чи служби.
        </p>
        <p style={{ textIndent: "30px" }}>
          Якщо виникає суперечка між учасниками цього сайту чи Служб, або між
          користувачами та будь-якою третьою стороною, ви погоджуєтеся, що
          Carcool не зобов’язана брати участь. У випадку, якщо у вас виникла
          суперечка з одним або декількома іншими користувачами, ви звільняєте
          Carcool, її директорів, посадових осіб, співробітників, агентів і
          правонаступників від претензій, вимог і збитків будь-якого роду чи
          характеру, відомих чи невідомих, підозрюваних або не підозрювані,
          розголошені чи нерозголошені, що виникають внаслідок таких суперечок
          та/або нашої Платформи або будь-яким чином пов’язані з ними.
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>Чи змінить Carcool Платформу?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Ми завжди намагаємося вдосконалювати наші Платформу, тому з часом вона
          можуть змінюватися. Ми можемо призупинити або припинити надання
          будь-якої частини Платформи, запровадити нові функції, накласти
          обмеження на певні функції чи обмежити доступ до частини чи всіх
          Платформи. Ми залишаємо за собою право видаляти будь-який Вміст із
          Платформи у будь-який час і з будь-якої причини (зокрема, але не
          обмежуючись, якщо хтось стверджує, що ви надали цей Вміст у порушення
          цих Умов), на власний розсуд і без попередження.
        </p>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>Чи треба платити за користування?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Реєстрація для користування Платформи безкоштовна, але деякі аспекти
          Платформи можуть вимагати сплати. Якщо ви вирішите використовувати ці
          платні аспекти Платформи, ви погоджуєтеся з Умовами оплати, наведеними
          нижче, оскільки ми можемо час від часу вносити до них зміни.
        </p>
        <p style={{ textIndent: "30px" }}>
          Ми залишаємо за собою право стягувати плату за певні або всі Послуги в
          майбутньому. Ми повідомимо вас перед тим, як будь-які Послуги, якими
          ви тоді користуєтеся, почнуть стягувати плату, і якщо ви бажаєте
          продовжувати користуватися такими Послугами, ви повинні сплатити всі
          відповідні збори за такі Послуги.
        </p>
        <p style={{ textIndent: "30px" }}>
          Ви повинні сплатити всі комісії та відповідні податки, пов’язані з
          нашою Платформою, до встановленої дати платежу (включаючи, але не
          обмежуючись, будь-які комісії, стягнені у зв’язку з використанням
          нашого стороннього платіжної системи "ПЛАТІЖНОЇ СИСТЕМИ"). Умови
          використання "ПЛАТІЖНА СИСТЕМА" можна знайти на сторінці
          "САЙТПЛАТІЖКИ"
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom d-flex justify-content-center">
          <strong>Комісії</strong>
        </h4>
        <h4 className="h4-margin-bottom ">
          <strong>Плата за публікації</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Наразі немає комісії за розміщення автомобіля Продавця на Платформі.
        </p>
        <h4 className="h4-margin-bottom ">
          <strong>Плата покупця</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Якщо ви переможете в торгах за автомобіль, указаний на Платформі, ви
          сплатите нам комісію («Плата покупця») у розмірі 4,5% від суми
          виграшної ставки, зазначеної на Платформі. Незважаючи на вищесказане,
          для кожної виграшної ставки на Платформі мінімальна плата Покупця
          становить 225 умовних одиниць. Усі комісії покупця не повертаються.
        </p>
        <p style={{ textIndent: "30px" }}>
          Якщо ви зробите ставку на Платформі, ми заморозимо частину коштів на
          вашій карткі. Після завершення аукціону ми знімемо всі такі заморозки,
          окрім заморозки з картки переможця торгів. Утримання з картки
          переможця торгів буде стягнуто як оплата комісії покупця одразу після
          завершення аукціону.
        </p>
        <p style={{ textIndent: "30px" }}>
          Будь ласка, відвідайте наші сторінки «Що таке Carcool» та «Продати
          автомобіль», щоб дізнатися більше про наші функції продукту.
        </p>
        <h4 className="h4-margin-bottom ">
          <strong>Платежі продавця</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Протягом дев’яноста (90) днів після завершення аукціону Продавець
          повинен надати компанії Carcool інформацію, необхідну йому для обробки
          будь-яких платежів Продавцю в рамках Платформи (включаючи, але не
          обмежуючись, інформацію про банківський рахунок. Якщо Продавець не
          дотримується вищезазначеного, Carcool не несе відповідальності за
          будь-які платіжні зобов’язання, які Carcool має перед Продавцем, і
          Продавець відмовляється від них.
        </p>
      </TextBlock>
      <TextBlock color={colorWhite} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>
            Що робити, якщо я хочу припинити використання Платформою?
          </strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Ви можете зробити це в будь-який час, зв’язавшись з нами за адресою{" "}
          <strong>carcool365@gmail.com</strong>; будь ласка, перегляньте нашу
          Політику конфіденційності, а також ліцензії вище, щоб зрозуміти, як ми
          обробляємо інформацію, яку ви надаєте нам після того, як ви припинили
          користуватися нашою Платформою
        </p>
        <p style={{ textIndent: "30px" }}>
          Carcool також має право припинити (або призупинити доступ до)
          використання вами Платформи або вашого облікового запису з будь-якої
          причини на наш розсуд, включаючи порушення вами цих Умов. Carcool має
          виключне право вирішувати, чи порушуєте ви будь-які обмеження,
          викладені в цих Умовах; наприклад, Учасник торгів, який вважає, що
          Продавець може порушити ці Умови, не може застосувати ці Умови проти
          цього Продавця.
        </p>
        <p style={{ textIndent: "30px" }}>
          Припинення дії облікового запису може призвести до знищення будь-якого
          Вмісту, пов’язаного з вашим обліковим записом, тому майте це на увазі,
          перш ніж вирішите припинити дію свого облікового запису.
        </p>
        <p style={{ textIndent: "30px" }}>
          Якщо ви помилково видалили свій обліковий запис, негайно зв’яжіться з
          нами за адресою <strong>carcool365@gmail.com</strong> – ми спробуємо
          допомогти, але, на жаль, ми не можемо обіцяти, що зможемо щось
          відновити.
        </p>
        <p style={{ textIndent: "30px" }}>
          Положення, які за своїм характером повинні залишатися в силі після
          припинення дії цих Умов, залишаються в силі. Наприклад, усі наведені
          нижче дії залишаться в силі після розірвання: будь-які зобов’язання,
          які ви маєте сплатити нам або відшкодувати нам, будь-які обмеження
          нашої відповідальності, будь-які умови щодо прав власності чи прав
          інтелектуальної власності та умови щодо суперечок між нами.
        </p>
      </TextBlock>
      <TextBlock color={colorBlue} className="cardText">
        <h4 className="h4-margin-bottom ">
          <strong>Що ще мені потрібно знати?</strong>
        </h4>
        <p style={{ textIndent: "30px" }}>
          Відмова від гарантії. Carcool та її ліцензіарів, постачальників,
          партнерів, материнських, дочірніх або афілійованих організацій, а
          також кожного з їхніх відповідних посадових осіб, директорів, членів,
          співробітників, консультантів, контрактних працівників, представників
          і агентів, а також кожного з їхніх відповідних правонаступників не
          роблять заяв або гарантій щодо Платформи, у тому числі, стосовно
          будь-якого Вмісту, що міститься на Платформі або доступ до якого
          здійснюється через Платформу, і Carcool не несе відповідальність за
          точність, відповідність авторським правам, законність або пристойність
          матеріалів, що містяться в Платформі або доступ до яких здійснюється
          за допомогою них, або будь-яких претензій, дій, судових процедур,
          витрат, видатків, збитків або зобов’язань, що виникають внаслідок
          використання або будь-яким чином пов’язані з вашою участю на
          Платформі.
        </p>
        <p style={{ textIndent: "30px" }}>
          <strong>
            CARCOOL НЕ РОБИТЬ ЖОДНИХ ЗАЯВ АБО ГАРАНТІЙ АБО НЕ НЕСЕ
            ВІДПОВІДАЛЬНОСТІ ЗА БУДЬ-ЯКИЙ АВТОМОБІЛЬ, ЩО РЕКЛАМУЄ АБО ПРОПОНУЄ
            ПРОДАВЕЦЬ ЧЕРЕЗ СЕРВІС (АБО БУДЬ-ЯКИЙ ВЕБ-САЙТ ЧИ СЕРВІС) І НЕ БУДЕ
            СТОРОНОЮ МІЖ ІНШИМ СТОРОННІМ ПОСТАЧАЛЬНИКОМ.
          </strong>
        </p>
        <p style={{ textIndent: "30px" }}>
          Відшкодування. Ви погоджуєтеся звільнити Carcool від будь-яких
          претензій, зобов’язань, збитків (фактичних і непрямих), збитків і
          витрат (включаючи гонорари адвокатів), що виникають або будь-яким
          чином пов’язані з будь-якими претензіями, пов’язаними з використання
          вами Платформи (включно з будь-якими діями, здійсненими третьою
          стороною з використанням вашого облікового запису) і порушення вами
          цих Умов. У разі такої претензії, позову чи дії («Претензія») ми
          спробуємо надіслати повідомлення про претензію за контактною
          інформацією, яку ми маємо для вашого облікового запису (за умови, що
          ненадання такого повідомлення не скасує або не зменшить ваші
          зобов'язання щодо відшкодування за цим Договором).
        </p>
      </TextBlock>
    </div>
  );
};

export default UseOfTerms;
