import React, { useState } from "react";
import styles from "../Pages/ExhibitingACar.module.css";
import Hint from "./Hint";

const Question = ({ id, title, hint, stateElement, setStateElement }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleShow = (value) => {
    setStateElement(value);
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  return (
    <div>
      <h4>
        {title}{" "}
        <Hint
          id={id}
          popoverOpen={popoverOpen}
          togglePopover={togglePopover}
          hint={hint}
          placement="top"
        />
        {typeof stateElement !== "undefined" && (
          <>
            <button
              type="button"
              className={
                stateElement ? styles.btnQuestionActive : styles.btnQuestion
              }
              onClick={() => handleShow(false)}
            >
              Ні
            </button>
            <button
              type="button"
              className={
                stateElement ? styles.btnQuestion : styles.btnQuestionActive
              }
              onClick={() => handleShow(true)}
            >
              Так
            </button>
          </>
        )}
      </h4>
    </div>
  );
};

export default Question;
