import React from "react";
import { useNavigate } from "react-router-dom";
import Identification from "../../UI/Identification";

const RegistrationConfirm = () => {
  const navigate = useNavigate();
  const openLogin = () => {
    navigate("/login");
  };
  return (
    <Identification
      header="Підтвердження аккаунту"
      contextText={
        <>
          <p>
            Ми надіслали вам електронний лист з інструкціями щодо підтвердження
            вашого акаунту. Будь ласка, перевірте свою електронну пошту.
          </p>
          <p style={{ display: "inline-block" }}>Повернутися до авторизації</p>
        </>
      }
      linkText="Увійти"
      clickEvent={openLogin}
      hideGoogleButton={true}
    >
      {" "}
    </Identification>
  );
};

export default RegistrationConfirm;
