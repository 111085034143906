import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./components/Contexts/AuthContext";

function PublicRoute({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return children;
}

export default PublicRoute;
