import React from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes, { AuthRoutes } from "./AppRoutes";
import Layout from "./components/Layout";
import "./custom.css";
import "./colors.css";
import { ApiProvider } from "./components/Contexts/ApiContext";
import { AuthProvider } from "./components/Contexts/AuthContext";
import { ToastProvider } from "./components/Contexts/ToastContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <ApiProvider>
          <ToastProvider>
            <Routes>
              {AuthRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}

              <Route element={<Layout />}>
                {AppRoutes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element}>
                    {route.children &&
                      route.children.map((childRoute, childIndex) => (
                        <Route
                          key={childIndex}
                          path={childRoute.path}
                          element={childRoute.element}
                        />
                      ))}
                  </Route>
                ))}
              </Route>
            </Routes>
          </ToastProvider>
        </ApiProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
